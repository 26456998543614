// base query component zum standardisierten
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import AUApi from "@/api";
import ChartBase from "./ChartBase";

export default abstract class BaseQueryChart extends ChartBase {
    @Prop() public siteData: any;
    protected showLoading: boolean = true;
    @Prop({ default: null }) public formular!: ALLFrame;
    public showZeitbereich: boolean = false;
    public titleZeitbereich: string = "";

    protected refreshing = false;
    public async refresh() {

        if (this.params?.aufgabe) {
            this.formular = this.params.aufgabe;
        }
        this.showLoading = true;
        if (this.refreshing) { return; }
        this.refreshing = true;
        let currentZeitbereich = this.$api.zeitbereich;
        const zeitbereichVon = this.$api.zeitbereich.von;
        const zeitbereichBis = this.$api.zeitbereich.bis;
        let bereich = "Monat";
        let vonDate = new Date(zeitbereichVon);
        let bisDate = new Date(new Date(zeitbereichBis).lastInMonth());
        currentZeitbereich = {
            bereich,
            von: vonDate,
            bis: bisDate,
        };
        if (this.params.abwZeitbereich) {
            bereich = this.params.abwZeitbereich;
            if (bereich === "Woche") {
                const heute = new Date();
                vonDate = new Date(heute.firstOfWeek());
                bisDate = vonDate.addDays(+6);
                this.showZeitbereich = true;
                const week = vonDate.getWeekNumber();
                this.titleZeitbereich = "KW: " + week.toFixed(0) + " ";
            } else if (bereich === "Jahr") {
                vonDate = new Date(vonDate.getFullYear(), 1, 0);
                vonDate = vonDate.firstOfMonth();
                bisDate = vonDate.addMonths(+11, true);
                bisDate = bisDate.lastInMonth();
            }
        }
        currentZeitbereich = {
            bereich,
            von: vonDate,
            bis: bisDate,
        };
        try {
            if (this.query) {
                const setztVector = "";
                let formAic = 0;
                if (this.formular) {
                    formAic = this.formular.aic;
                }
                const r = await AUApi.Current.getQuery(
                    this.query,
                    undefined,
                    this.aic ?? null,
                    currentZeitbereich ?? null,
                    0,
                    false,
                    this.bewegungsdaten,
                    setztVector,
                    formAic,
                );
                this.dataLoaded(r.data);
            } else if (this.queryData) {
                this.dataLoaded(this.queryData);
            } else if (this.siteData) {
                if (this.siteData.queryData) {
                    this.dataLoaded(this.siteData.queryData);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.refreshing = false;
            this.showLoading = false;
        }
    }

    protected dataLoadedBase(data: QueryResponse) {
        this.lastQueryResponse = data;
        this.dataLoaded(data);
    }

    // implement this to show the data in the UI...
    protected abstract dataLoaded(data: QueryResponse): void;

    protected QvAICsChanged(): void {
        // nothing to do here
    }
}
