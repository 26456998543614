












































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import {
  getDateFromInput,
  getMask,
  highlightDays,
  valueToFormatStr,
} from "./CalenderHelper";
import { RenderDayCellEventArgs } from "@syncfusion/ej2-vue-calendars";
import { findSperrDate } from "@/SperreHelper";

@Component
export default class EditDatePicker extends EditComponent {
  // eingabeFelder
  @Prop({ default: null }) private formatProp!: string | null;
  @Prop({ default: true }) public onFocus!: boolean;
  protected inputDate: any = "";
  protected format1: string = "dd.MM.yyyy"; // übergabe Format und ANZEIGE Format Muss ident sein!
  protected format: string = "dd.MM.yyyy";
  protected inputFormat: any = null; // auf maske abgestimmter formatstr
  protected strict: boolean = true;
  private start: string = "Month";
  private depth: string = "Year";
  private showLabel: boolean = false;
  private initalized: boolean = false;
  protected get disabledText() {
    //      return this.valueToFormatStr(this.currentDate, this.format);

    if (this.currentDate) {
      return this.valueToFormatStr(this.currentDate, this.format);
    } else {
      return this.valueToFormatStr(this.data, this.format);
    }
  }

  // Datum für Picker
  public currentDate: any = null;
  protected currentDateValue: Date | null = null;
  protected minDate: any = ""; // auf SperrDAtum setzen
  protected maxDate: any = "";

  protected holidayDate: any = []; // muss mit den Datums aus der Holiday tabelle gefüllt werden

  protected isFocused: boolean = false;
  // private errorMessages: Array<string> = [];

  protected errorText: string = "";
  protected text: string = "";
  @Prop({ default: null }) public sperrBegriff!: any | null;

  public mounted() {
    try {
      this.mountedBaseDatePicker();
      this.initalized = true;
      this.$emit("mountFinished");
    } catch (err) {
      console.error(err);
    }
  }
  protected mountedBaseDatePicker() {
    this.mountedBase();
    const sperrDate = this.getSperrDate(this.sperrBegriff);
    if (sperrDate) {
      this.minDate = new Date(sperrDate);
    }
    if (this.formatProp) {
      this.format = this.formatProp;
    }
    if (
      this.column?.datatype === "Eintritt" ||
      this.column?.datatype === "Austritt" ||
      this.column?.datatype === "Stichtag"
    ) {
      const bis = this.$api.zeitbereich.bis;
      this.maxDate = bis;
    }
    if (this.column?.meta?.format) {
      this.format = this.column.meta.format;
      if (this.format === "MM.yyyy") {
        this.start = "Year";
        this.depth = "Year";

        // this.format1 = this.format.replace(/(EE)/g, "");
      }
    }
    let date: any = null;
    const initValue = this.getInitValue();
    if (this.enabled) {
      if (initValue) {
        if (typeof initValue === "string") {
          // wenn string dan hoffentlich ein iso
          date = new Date(initValue);
          if (isNaN(date)) {
            // ungültige ignorieren wir ;)
            date = null;
          }
        } else if (
          // schaun obs ein gültiges datum is
          Object.prototype.toString.call(date) === "[object Date]" &&
          !isNaN(date)
        ) {
          date = initValue;
        }
      } else if (this.column) {
        if (this.column.meta.neuArt && this.column.meta.neuArt === "Sync") {
          // Datum auf VON EditZeitbereich setzen....???
          date = new Date().toISOStringWithTZ();
        } else if (this.column.meta.auto && initValue === undefined) {
          // Datum auf VON EditZeitbereich setzen....
          date = new Date().toISOStringWithTZ();
        } else {
          date = initValue;
        }
        date = initValue;
      }

      if (this.column && this.column.meta && this.column.meta.format) {
        this.format = this.column.meta.format;
        if (this.format === "ww yyyy") {
          this.format = "dd.MM.yyyy";
        }
        if (this.currentDate === null) {
          this.format = this.format.replace(/(EE)/g, "");
        }
      }
    }
    // Maskierung ermittlen und Wert passend formatieren...
    if (date) {
      this.showLabel = true;
      this.currentDateValue = date;
      this.currentDate = this.valueToFormatStr(date, this.format);
    } else {
      this.currentDateValue = null;
      this.currentDate = "";
    }
  }

  protected dateChanged(d: { event: Event; element: HTMLElement }) {
    if (this.currentDate !== null) {
      this.showLabel = true;
    } else {
      this.showLabel = false;
    }
    try {
      if (d && d.event && d.event.type === "blur") {
        return;
      }

      let val = null;
      if (!this.currentDate) {
        this.currentDateValue = null;
      } else if (typeof this.currentDate === "string") {
        this.currentDateValue = this.getDateFromInput(this.currentDate, this.format);
      } else if (
        typeof this.currentDate === "object" &&
        this.currentDate.toISOStringWithTZ
      ) {
        this.currentDateValue = this.currentDate;
      }

      if (this.currentDateValue) {
        val = this.currentDateValue.toISOStringWithTZ();
      }
      this.validate();

      this.currentDate = val ? new Date(val) : null;
      this.valueChanged(val);
    } catch (err) {
      console.error(err);
    }
  }

  public validate() {
    try {
      if (this.disabled) {
        this.errorText = "";
        return true;
      } // disabled Controls sind IMMER valide ;)
      if (this.required) {
        let mydate: any = this.currentDate || this.currentDateValue;
        console.log(mydate);
        if (typeof mydate === "string") {
          mydate = new Date(mydate);
        }
        if (mydate && !isNaN(mydate)) {
          this.errorText = "";
          return true;
        }
        this.errorText = this.requiredMessage;
        return false;
      }

      this.errorText = "";
      return true;
    } catch (err) {
      console.error(err);
    }
    return false;
  }

  public valueToFormatStr(value: Date, format: string) {
    try {
      return valueToFormatStr(value, format);
    } catch (err) {
      console.error(err);
    }
    return "";
  }

  protected getDateFromInput(input: string, format: string): Date | null {
    this.errorText = "";

    if (!input && this.required) {
      this.errorText = this.requiredMessage;
      return null;
    }

    const res = getDateFromInput(input, format);
    if (res.error) {
      this.errorText = res.error;
    } else {
      this.errorText = "";
    }
    return res.date;
  }

  protected getMask(format: string) {
    try {
      return getMask(format);
    } catch (err) {
      console.error(err);
    }
    return "";
  }

  private highlightDays(args: RenderDayCellEventArgs): void {
    try {
      return highlightDays(args, this);
    } catch (err) {
      console.error(err);
    }
  }
  protected getSperrDate(begriff: any) {
    const me = this;
    const aic = me.$api.user.aic;
    if (this.query) {
      const t = findSperrDate(this.query, aic, me);
      if (t) {
        const sperrDate = t.sperrDate;
        return new Date(sperrDate);
      }
    }
  }
}
