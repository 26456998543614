






























































































































































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import {
  Schedule,
  ScheduleComponent,
  Day,
  Week,
  TimelineMonth,
  TimelineViews,
  WorkWeek,
  Month,
  Agenda,
  Resize,
  PopupOpenEventArgs,
  SchedulePlugin,
  DragAndDrop,
  Print,
  Year,
  TimelineYear,
  ScheduleModel,
  ICalendarExport,
  ICalendarImport,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  ExcelExport,
} from "@syncfusion/ej2-vue-schedule";
Schedule.Inject(Day, Week, WorkWeek, Month, TimelineMonth, Year, TimelineYear, Agenda);
import { ContextMenuPlugin, ToolbarModel } from "@syncfusion/ej2-vue-navigations";
import {
  createElement,
  setCulture,
  extend,
  Internationalization,
} from "@syncfusion/ej2-base";
import editorTemplate from "./Templates/Planung.vue";
import resourceTemplate from "./Templates/ResourceHeaderDienstplan.vue";
import TooltipTemplate from "./Templates/TooltipTemplate.vue";
import cellTemplate from "./Templates/CellTemplate.vue";
import {
  getKZValue,
  getKZValueDisplay,
  getKZTitle,
  getKennungValue,
  getKZColumn,
} from "@/DataHelper";
import { Globals } from "@/Globals";
import Constants from "@/Constants";
import { calcFunctionNew } from "@/CalcHelperStatic";
import { row, updateData } from "@syncfusion/ej2-vue-grids";
import { getWeekdayNamesShort } from "@/editor/CalenderHelper";
import { showSnackbar, showDialog, printVueElement } from "@/UIHelper";
import { findSperrDate } from "@/SperreHelper";
import { AxiosResponse } from "axios";
import BaseFilter from "./common/BaseFilter.vue";

Vue.use(ContextMenuPlugin);
Vue.use(SchedulePlugin);

declare interface CalendarEntry {
  date: Date;
  StartTime: Date;
  startTime: Date;

  EndTime: Date;
  endTime: Date;

  aic_Bew_pool: number;
  aic_Stamm: number;
  IsReadonly: boolean;
  Subject: string;
  Description: string;
  Description2: string;
  Location: string;
  LocationText: string;

  GroupID: string;
  name: string;
  editorComponent: string;
  abwesenheit: boolean;
}

// wir verwenden Properties dies laut defintion nicht gibt !?!...
declare interface OurPopupOpenEventArgs extends PopupOpenEventArgs {
  requestType: "toolbarItemRendering";
  items: ToolbarModel[];
  date: Date;
  data: CalendarEntry; // only assumed in our scenario
  type: "QuickInfo" | "Editor" | "ViewEventInfo";
  cancel: boolean;
}
export declare interface Mitarbeiter {
  aic: number;
  bezeichnung: string;
  favorite?: boolean;
}

@Component({
  components: { TooltipTemplate, resourceTemplate, cellTemplate },
  provide: {
    schedule: [
      Day,
      Week,
      WorkWeek,
      Month,
      Agenda,
      TimelineViews,
      TimelineMonth,
      Resize,
      DragAndDrop,
      Print,
      Year,
      TimelineYear,
      ICalendarExport,
      ICalendarImport,
      ExcelExport,
    ],
  },
})
export default class BaseSyncSchedule extends Vue {
  // public components: any = { scheduler: ScheduleComponent };
  private schedule: Schedule = new Schedule({
    height: "auto",
  });

  @Prop() public query!: string | undefined; // per default nicht initialisiert ;)
  @Prop() public editorComponent!: string;
  @Prop() public editorComponent1!: string;
  @Prop({ default: false }) public readOnly!: boolean;
  @Prop({ default: false }) public dienstplan!: boolean;
  @Prop() public currentView!: string;
  @Prop({ default: false }) public showMonth!: boolean;
  @Prop({ default: false }) public showTimeLineMonth!: boolean;
  @Prop({ default: false }) public showTimeLineYear!: boolean;
  @Prop({ default: false }) public showDay!: boolean;
  @Prop({ default: false }) public readBewilligt!: boolean;
  @Prop({ default: false }) public onlyEdit!: boolean;
  @Prop({ default: false }) public abteilung!: boolean;
  @Prop({ default: true }) public showWeek!: boolean;
  @Prop({ default: true }) public showQuickInfo!: boolean;
  @Prop({ default: false }) public gotoToday!: boolean;
  @Prop() public gotoAic!: string; // darf nicht auf NUMBEr geändert werden - sonst geht das hinscrollen nicht mehr!
  @Prop({ default: false }) public dialogAicStamm!: boolean;
  @Prop({ default: null }) public saveQuery!: string | null;
  @Prop({ default: null }) public saveModell!: string | null; // prüft VOR dem Speichern auf Doppelbuchungen!
  @Prop({ default: "StartEnd" }) public saveProperty!: string | null;
  @Prop() public abwZeitraum!: any;
  @Prop({ default: false }) public showGroup!: boolean;
  private showGroupMutable: boolean = false;
  @Prop({ default: true }) public allowDragAndDrop!: boolean;
  @Prop({ default: null }) public formular!: ALLFrame;
  @Prop({ default: false }) public setMinDate!: boolean;
  @Prop({ default: false }) public useCellTemplate!: boolean;
  @Prop({ default: false }) public persistence!: boolean;
  @Prop({ default: false }) public reloadGroup!: boolean;
  @Prop({ default: false }) public reload!: boolean;
  @Prop({ default: false }) public anzeigeStunden!: boolean;
  @Prop({ default: 0 }) public dsHeight!: number;
  @Prop({ default: false }) public showICS!: boolean;
  @Prop() public group2!: string;
  // private locale = "de-AT";
  // @Prop() public groupUebergabe: any = {
  //   enableCompactView: false,
  //   resources: ["GroupData", "Employee"],
  // };
  protected group: any = {
    enableCompactView: false,
    resources: ["GroupData", "Employee"],
  };
  @Prop() public queryMitarbeiter!: string | undefined;
  protected employee: Array<{
    GroupID: number;
    Text: string;
    CssClass: string;
    Color: string;
    groupIDField: string;
    Designation: string;
    avatar: string;
    showImage: boolean;
    showAvatar: boolean;
    ZusatzText: string;
    Urlaub: string;
    Daten: any;
    negativ: number;
    workDays: any;
    startHour: string;
    endHour: string;
  }> = [];
  protected employeeOriginal: Array<{
    GroupID: number;
    Text: string;
    CssClass: string;
    Color: string;
    groupIDField: string;
    Designation: string;
    avatar: string;
    showImage: boolean;
    showAvatar: boolean;
    ZusatzText: string;
    Urlaub: string;
    Daten: any;
    negativ: number;
    workDays: any;
    startHour: string;
    endHour: string;
  }> = [];
  protected groupData: Array<{
    GroupID: number;
    Text: string;
    Color: string;
    Daten: any;
  }> = [];
  private allowDragAndDropMutable!: boolean;
  private showQuickInfoMutable: boolean = false;
  private readOnlyMutable: boolean = false;
  private activeView: string = "Month";
  private showLoading: boolean = false;
  private header: any = { bezeichnung: " -- " };
  private queryAIC: number = 0;
  private initialized: boolean = false;
  private myHeight: number = window.innerHeight;
  private minDate: Date | null = new Date(1970, 0, 1);

  private allColumns: DataColumn[] = [];
  private zeitbereich: APIZeitbereich | null = null;

  private eventZeitbereich: any;
  private rowdata: any[] = [];
  private sperrDate: any = undefined;
  private allowMultiple: boolean = true;
  private showAbteilung: boolean = true;
  private groupAic: string = "";
  private groupBegriff: string = "";
  private checked: boolean = false;
  private jarVersion: boolean = false;
  private allowDeleting: boolean = true;
  private showFilter: boolean = false;
  protected filteredData: any[] = [];

  private selectedDate: Date | null = this.$api.zeitbereich.von; // new Date();
  private eventSettings: any = {
    dataSource: [],
    enableTooltip: true,

    fields: {
      location: { name: "Location" },
      description: { name: "Description" },
    },
  };
  private allowedExtensions: any = ".ics";
  private buttons: any = {
    browse: "Choose file",
  };
  private cssClass: any = "dvh";

  private timeScale: any = { enable: false };
  private workDays: any;

  private showWeekNumber: boolean = false;
  private dateFormat: string = "dd.MM.yyyy";
  private allowResizing: boolean = true;
  private showDialog: boolean = false;
  private von: any = "";
  private bis: any = "";
  private Subject: any = "";
  private bewilligung: boolean = false;
  private bewilligt: boolean = false;
  private offen: boolean = false;
  private htmText: string = "";
  private showFullWidth: boolean = false;
  private dialog: any = {
    zeitbereich: null,
    item: null,
    aic: null,
    editorComponent: "",
    groupID: null,
    groupName: "",
    abwesenheit: false,
  };
  private showText: boolean = false;
  private avatarIcon: any;
  private savedGroupIndex: any;
  private currentGroupIndex: any;
  private queryData: QueryResponse | null = null;
  private printItems!: PrintItems[] | undefined;
  private modellItems!: ALLModell[] | undefined;
  // private workHours!: {};
  private startHour: string = "00:00";
  private endHour: string = "24:00";
  private startHourShow: string = "08:00";
  protected springer: Mitarbeiter[] = [];
  private vector: number[] = [];
  private showSpringer: boolean = false;

  protected created() {
    console.log(this.$attrs);
    const me = this;
    me.showText = false;
    // beim Stellverterter sollen Daten in der Vergangenheit nicht angezeigt werden
    // maximal der letzte Monat
    if (this.currentView) {
      this.activeView = this.currentView;
    }
    if (this.setMinDate) {
      const d = new Date();
      const ersterTag = d.firstOfMonth();
      const dd = ersterTag.getDate();
      const mm = ersterTag.getMonth();
      const yyyy = ersterTag.getFullYear();
      me.minDate = new Date(yyyy, mm, dd);
    }
    this.$root.$on("windowResize", (a: any) => me.onResizeStart(a));
  }

  public mounted() {
    console.log("schedule start... ");
    // this.group = this.groupUebergabe;
    const findSpringer = this.checkBegriff("Web_SpringerSetzen");
    if (findSpringer) {
      this.showSpringer = true;
    }
    this.showGroupMutable = this.showGroup;
    this.showQuickInfoMutable = this.showQuickInfo;
    this.readOnlyMutable = this.readOnly;
    this.allowDragAndDropMutable = this.allowDragAndDrop;
    const jar = this.$api.user.AllVersion;
    // this.workHours = { highlight: true, start: this.startHour, end: this.endHour };
    if (this.activeView === "Week" || this.activeView === "WorkWeek") {
      // this.startHour = "00:00";
      // this.endHour = "24:00";
      this.timeScale = { interval: 60, slotCount: 1 };
      // this.workDays = [1, 2, 3, 4, 5, 6, 7];
    } else if (
      this.activeView === "TimelineWeek" &&
      (this.dienstplan || this.anzeigeStunden)
    ) {
      // this.checked = true;
      // nicht gruppiert ist die Darstellung aktuell falsch...
      this.timeScale = { interval: 120, slotCount: 1 };
      // this.workDays = [1, 2, 3, 4, 5, 6];
      // this.workHours = { highlight: true, start: "08:00", end: "20:00" };
      // this.startHour = "08:00";
      // this.endHour = "20:00";
    } else {
      this.timeScale = { enable: false };
    }
    this.jarVersion = !jar.includes("5.15"); // erst ab 5.16 können pers. Parameter gespeichert werdeb
    if (this.abwZeitraum) {
      let von: any;
      if (this.abwZeitraum.neuerZeitbereich) {
        von = this.abwZeitraum.neuerZeitbereich;
      } else {
        von = this.abwZeitraum;
      }
      if (von) {
        this.selectedDate = von;
      }
      if (!this.zeitbereich) {
        if (this.abwZeitraum) {
          let bis = new Date();
          let bereich = "Tag";
          if (
            this.activeView === "Week" ||
            this.activeView === "TimelineWeek" ||
            this.activeView === "WorkWeek"
          ) {
            von = new Date(von.firstOfWeek());
            this.selectedDate = von;
            bis = von.addDays(+6);
            bereich = "Woche";
          } else {
            von = new Date(von.firstOfMonth());
            this.selectedDate = von;
            bis = new Date(von.lastInMonth());
          }
          this.zeitbereich = {
            von,
            bis,
            bereich,
          };
        }
      }
    }
    if (!this.zeitbereich) {
      // wenn CurrentView Woche ist - dann soll er auch nur für diese Woche die daten laden
      // und nicht für den gesamt eingestellten zeitraum
      if (
        this.activeView === "Week" ||
        this.activeView === "TimelineWeek" ||
        this.activeView === "WorkWeek"
      ) {
        this.reloadNewZeitbereich();
      } else {
        this.zeitbereich = this.$api.zeitbereich;
      }
    }
    if (
      this.activeView === "Week" ||
      this.activeView === "TimelineWeek" ||
      this.activeView === "WorkWeek"
    ) {
      // this.selectedDate = new Date();
      this.onZeitraum();
    }
    setCulture(this.$api.user.sprache.toLowerCase());
    this.myHeight = window.innerHeight - 200;
    if (this.showGroupMutable) {
      this.myHeight = window.innerHeight - 300;
    }
    if (this.dsHeight) {
      this.myHeight = this.dsHeight;
    }
    if (this.$isPhone()) {
      this.showWeekNumber = false;
      this.allowDragAndDropMutable = false;
      this.showFullWidth = true;
      // Delete muss in QuickInfo weggeschalten werden
      if (this.readOnlyMutable || this.onlyEdit) {
        this.allowDeleting = false;
      }
    }
    if (this.formular) {
      this.printItems = this.formular.druck ?? [];
      this.modellItems = this.formular.mod2 ?? [];
    }
    if (this.gotoToday) {
      this.selectedDate = new Date();
    }
    if (this.queryMitarbeiter) {
      if (this.group2) {
        this.loadResources(true);
      } else {
        this.loadResources(false);
      }
    } else {
      this.reloadData();
    }
    this.getSperrDate();
  }

  protected checkBegriff(kennung: string) {
    const x = this.$globalsKennung(Globals.Begriff, kennung, false);
    return x;
  }
  public onDataBound() {
    if (this.activeView === "TimelineWeek" && this.dienstplan) {
      const schedule = this.getSchedule();
      if (this.employee && this.employee.length > 0) {
        let resultHour = this.employee.map((t) => t.startHour);
        resultHour = resultHour.sort();
        this.startHour = resultHour[0];
        let resultHourEnd = this.employee.map((t) => t.endHour);
        resultHourEnd.join();
        resultHourEnd.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
        resultHourEnd = resultHourEnd.reverse();
        this.endHour = resultHourEnd[0];
      }
      const appointments = schedule.ej2Instances.element.querySelectorAll(
        ".e-appointment"
      );
      // das darf ich nicht machen - da sonst beim Dienstplan immer beim ersteinstige alles ganz oben pickt!
      for (let y = 0; y < appointments.length; y++) {
        // appointments[i].style.top = "0%";
        appointments[y].style.height = "60px";
      }
    }
    if (this.gotoAic) {
      const resourceId = this.gotoAic;
      const scheduleDok = document.querySelector(".e-schedule");
      if (scheduleDok) {
        const scheduleObj = (scheduleDok as any).ej2_instances[0];
        scheduleObj.scrollToResource(resourceId as string, "Employee"); // scrolling to specific resource
        // scheduleObj.scrollTo(null, new Date()); // scrolling to specifce data
      }
    }
    if (this.activeView === "Week" || this.activeView === "WorkWeek") {
      const scheduleDok = document.querySelector(".e-schedule");
      if (scheduleDok) {
        const scheduleObj = (scheduleDok as any).ej2_instances[0];
        scheduleObj.scrollTo(this.startHourShow); // scrolling to specifce data
        // const renderDate = scheduleObj.activeView.getRenderDates();
        // scheduleObj.resetWorkHours();

        // // for (let i = 0; i < renderDate.length; i++) {
        // //   this.employee.forEach((x) => {
        // //     scheduleObj.setWorkHours([renderDate[i]], x.dayWorkStart, x.dayWorkEnd);
        // //   });
        // // }
      }
    }
  }
  private onSubmit() {
    if (this.gotoAic) {
      const resourceId = this.gotoAic;
      const scheduleDok = document.querySelector(".e-schedule");
      if (scheduleDok) {
        const scheduleObj = (scheduleDok as any).ej2_instances[0];
        if (scheduleObj) {
          scheduleObj.scrollToResource(resourceId as string, "Employee");
        }
      }
    }
  }

  private checkDateRange(von: Date, bis: Date, aic: number): boolean {
    // bei TRUE darf ich buchen - bei FLASE nicht
    const rows = [...this.rowdata];
    for (let i = 0; i < rows.length; i++) {
      const eventVon = new Date(rows[i].StartTime);
      const eventBis = new Date(rows[i].EndTime);
      // bis = 1 Tag mehr!
      if (Date.doesRangesIntersect(eventVon, eventBis, von, bis)) {
        // wurde die bestehende Planung geändert, dann darf ich nicht auf FALSE setzen!
        console.log(
          "intersect: " + von.toISOStringWithTZ() + " " + bis.toISOStringWithTZ()
        );
        console.log(
          "           " +
            eventVon.toISOStringWithTZ() +
            " " +
            eventBis.toISOStringWithTZ()
        );

        if (!aic || aic !== rows[i].aic_Bew_pool) {
          showSnackbar({
            text: this.$globalsBegriffMemo(
              Globals.Begriff,
              "Ueberschneidung",
              "Überschneidung!"
            ),
            color: "error",
          });
          return false;
        }
      }
    }
    return true;
  }

  private lastDt: string | null = null;
  private lastBereich: string = "Tag";
  protected actionCompleted(args: any) {
    console.log("schedule action completed: " + args.action, args.requestType);
    if (args.length && args.length > 0) {
      args = args[0];
    }
    if (
      args.requestType === "viewNavigate" ||
      args.requestType === "dateNavigate" ||
      (args.requestType === "toolBarItemRendered" && this.activeView === "TimelineDay")
    ) {
      this.onZeitraum();
    } else if (
      args.requestType === "eventChanged" ||
      args.requestType === "eventCreated"
    ) {
      if (args.changedRecords && args.changedRecords.length > 0) {
        showSnackbar("Daten werden bearbeitet");

        this.showLoading = true;
        this.showSchedule = false;

        for (let i = 0; i < args.changedRecords.length; i++) {
          const event = args.changedRecords[i];

          const dateCheck = this.checkDateRange(
            event.StartTime,
            event.EndTime,
            event.aic_Bew_pool
          );
          if (dateCheck) {
            console.log("intersect line 304");
            const saveCol = getKZColumn(this.saveProperty + "");
            const zeitArtCol = getKZColumn("subject");
            let aicAbfAll = 0;
            if (this.saveQuery !== "") {
              const aicAbfAllBez = this.$globalsKennung(Globals.Begriff, this.saveQuery);
              aicAbfAll = aicAbfAllBez.aic;
            }
            // const data: any = event; // alles speichern
            const data: any = { aic_Bew_pool: event.aic_Bew_pool }; // nur von/bis speichern
            const bis = new Date(event.EndTime).addDays(-1); // Zeitraum muss -1 tag sein - sonst geht das Modell nichT!
            this.eventZeitbereich = {
              von: event.StartTime,
              bis,
              bereich: "Tag",
            };

            data[saveCol.name] = {
              von: event.StartTime.toISOStringWithTZ(),
              bis: event.EndTime.toISOStringWithTZ(),
              IsAllDay: event.IsAllDay,
              dauer: (event.StartTime as Date).diffInDays(event.EndTime) * 86400 - 86400,
            };

            data[zeitArtCol.name] = event[zeitArtCol.name];
            const vonZeitbereich = this.eventZeitbereich.von.toISOStringWithTZ();
            const bisZeitbereich = this.eventZeitbereich.bis.toISOStringWithTZ();
            const modellZeitbereich = {
              von: new Date(vonZeitbereich),
              bis: new Date(bisZeitbereich),
              bereich: "Tag",
            };
            this.onSave(aicAbfAll, data, "", false, modellZeitbereich);
          } else {
            showSnackbar({
              text: this.$globalsBegriffMemo(
                Globals.Begriff,
                "Ueberschneidung",
                "Überschneidung!"
              ),
              color: "error",
            });

            this.reloadNewZeitbereich();
            return;
          }
        }
      }
    }
  }
  private onZeitraum() {
    const neuerZeitbereich = this.selectedDate;
    this.$emit("zeitraumChanged", { neuerZeitbereich });
  }
  // wenn Events verschoben werden - soll das Scrollen unterbunden werden
  private onDragStart(args: any) {
    args.scroll = { enable: false };
  }
  // wenn Events vergrößert werden - soll das Scrollen unterbunden werden
  private onResizeStart(args: any) {
    args.scroll = { enable: false };
    this.myHeight = window.innerHeight - 200;
    if (this.showGroupMutable) {
      this.myHeight = window.innerHeight - 300;
    }
    if (this.dsHeight) {
      this.myHeight = this.dsHeight;
    }
    // const schedule = this.getSchedule();
    // const appointments = schedule.ej2Instances.element.querySelectorAll(".e-appointment");
    // for (let i = 0; i < appointments.length; i++) {
    //   // appointments[i].style.top = "0%";
    //   appointments[i].style.height = "60px";
    // }
  }
  private isReadOnly(data: any) {
    this.getSperrDate();
    return data.EndTime < this.sperrDate;
  }
  // prüfung Periodensperre
  protected getSperrDate() {
    const me = this;
    const aic = me.$api.user.aic;
    if (this.query) {
      const t = findSperrDate(this.query, aic, me);
      if (t) {
        let sperrDate = t.sperrDatum;
        // einen Tag wegrechnen - sonst wird der 1. des Monats gesperrt
        // es gilt da Datum aber Exclusiv!
        sperrDate = sperrDate.addDays(-1);
        this.sperrDate = sperrDate.toISOStringWithTZ();
        return new Date(sperrDate);
      }
    }
  }

  protected onSave(
    aicAbfAll: number,
    data: any,
    backupdata: any | null = null,
    fromCalc: boolean = false,
    zeitbereich?: any
  ) {
    const me = this;
    if (zeitbereich) {
      this.eventZeitbereich = zeitbereich;
    }
    if (this.saveModell && !fromCalc) {
      // gibt es ein Prüfmodell muss das vorher aufgerufen werden!
      // zeitbereich = Planungsdaten müssen übergeben werden
      // Modell prüft dann für diesen bereich ob es daten gibt!
      const varName = "BewAic";
      const varUbergabe: any[] = [];
      if (data.aic_Bew_pool) {
        const wert = data.aic_Bew_pool;

        varUbergabe.push({
          var: varName,
          type: "double",
          wert,
          art: 1,
          user: this.$api.user.user,
          perm: false,
        });
      }
      calcFunctionNew(
        this.saveModell,
        this.$api.user.aic,
        false,
        me,
        this.eventZeitbereich,
        varUbergabe
      )
        ?.then((response: any) => {
          if (response !== "Error") {
            me.onSave(aicAbfAll, data, backupdata, true, this.eventZeitbereich);
          }
        })
        .catch((error: any) => {
          // hübsche Fehlerbehandlung
          showSnackbar({
            text: error,
            color: "error",
          });
          console.log("ModellError:" + error);
        });

      return;
    }

    let AbfAll = 0;
    if (typeof aicAbfAll === "number") {
      AbfAll = aicAbfAll; // Abfrage für Planung speichern
    }
    me.showSchedule = true;
    me.showLoading = false;
    console.log("onsave");
    this.$api
      .SaveData(
        this.header,
        AbfAll,
        data.aic_Bew_pool,
        data,
        this.allColumns,
        this.eventZeitbereich,
        backupdata,
        (res: any) => {
          if (!res.nothingHappend) {
            // wenn nix passiert is aber weitermachen ;)
            showSnackbar(
              me.$globalsBegriffMemo(
                Globals.Begriff,
                "Datensatz_gespeichert",
                "Daten wurden gespeichert"
              )
            );
            // me.reloadNewZeitbereich();
          } else {
            showSnackbar(
              me.$globalsBegriffMemo(
                Globals.Begriff,
                "Datensatz_keine_Aenderung",
                "Daten wurden nicht verändert, kein Speichern durchgeführt!"
              )
            );
          }
        },
        (ex: any) => {
          let errorMessage = "";
          if (ex.response) {
            errorMessage = ex.response.data.error;
          } else {
            errorMessage = ex;
          }
          this.$root.$emit("alert", {
            text: errorMessage,
            type: "error",
          });
          console.error("ERROR: " + ex);
          // me.reloadNewZeitbereich();
        }
      )
      .then(() => me.reloadNewZeitbereich());
  }
  private reloadNewZeitbereich() {
    const me = this;
    const schedule = this.getSchedule();
    const resource = schedule.ej2Instances.getResourcesByIndex(1);
    this.savedGroupIndex = schedule.ej2Instances.uiStateValues.groupIndex;
    console.log(this.savedGroupIndex);
    // const selected = resource.groupData.GroupID;
    // const resourceCollection = schedule.ej2Instances.getResourceCollections();
    // const selected1 = schedule.ej2Instances.getResourceCollections();
    // const resourceIndex = resource.groupData.getSelectedElements();
    let dates = [];
    if (schedule) {
      dates = schedule.getCurrentViewDates() as any[];
    }
    const von = new Date(Math.min.apply(null, dates));
    let bis = new Date(Math.max.apply(null, dates));
    const diff = Math.round((bis.valueOf() - von.valueOf()) / (1000 * 60 * 60 * 24)) + 1;
    const midDate = von.addDays(diff / 2); // immer in die mitte scrollen
    let selectedDate = new Date();
    if (dates.length > 20) {
      if (me.currentView !== "TimelineMonth") {
        me.activeView = "Month";
      } else if (me.currentView === "TimelineMonth") {
        me.activeView = "TimelineMonth";
      } else {
        this.activeView = "Month";
      }
      // vermuten mal monatsansicht ;)
      selectedDate = midDate.firstOfMonth(); // also monatserster
    } else if (dates.length > 1 && dates.length <= 7) {
      if (me.currentView === "TimelineWeek" || me.activeView === "TimelineWeek") {
        // me.activeView = "TimelineWeek";
      } else if (me.currentView === "WorkWeek") {
        me.activeView = "WorkWeek";
      } else {
        me.activeView = "Week";
      }
      const monday = dates.find((date: Date) => date.getDay() === 1);
      if (monday) {
        // Montag verwenden
        selectedDate = monday;
      } else {
        selectedDate = midDate; // sonst in die Mitte ;)
      }
    } else if (dates.length === 1) {
      if (me.activeView === "TimelineYear" || me.activeView === "Year") {
        // me.currentView = me.activeView;
        bis = new Date(bis.getFullYear(), 12, 0);
        selectedDate = von;
      } else {
        // TagesAnsicht verwenden
        me.activeView = "TimelineDay";
        selectedDate = midDate;
        // sonst in die Mitte ;)}
      }
    } else {
      selectedDate = midDate; // sonst in die Mitte ;)
    }
    if (!isNaN(selectedDate.valueOf())) {
      me.selectedDate = selectedDate;
    }

    console.log("new range " + von.toISOStringWithTZ() + " : " + bis.toISOStringWithTZ());
    if (this.selectedDate) {
      if (this.selectedDate.toISOStringWithTZ) {
        console.log(
          "showing new Date - " + diff + " : " + this.selectedDate?.toISOStringWithTZ()
        );
      } else {
        console.log("showing new Date - " + diff + " : " + this.selectedDate);
      }
    }
    this.showSchedule = false;

    let bereich = "Tag";
    if (
      this.activeView === "Week" ||
      this.activeView === "TimelineWeek" ||
      this.activeView === "WorkWeek"
    ) {
      bereich = "Woche";
    } else if (this.activeView === "TimelineMonth") {
      bereich = "Monat";
    }

    this.lastBereich = bereich;
    me.zeitbereich = {
      von,
      bis,
      bereich,
    };
    if (me.reloadGroup) {
      if (this.queryMitarbeiter) {
        if (this.group2) {
          this.loadResources(true);
        } else {
          this.loadResources(false);
        }
      } else {
        this.reloadData();
      }
    } else {
      this.$nextTick(() => {
        this.reloadData();
      });
    }
  }

  private showSchedule: boolean = false;
  protected onActionBegin(args: any) {
    if (args.requestType === "toolbarItemRendering" && this.showICS) {
      const exportItem = {
        align: "Right",
        showTextOn: "Both",
        // prefixIcon: "e-icon-schedule-excel-export",
        text: "\u21E9" + " ICS Export",
        // cssClass: ".e-excel-export",
        click: this.onClick.bind(this),
      };
      args.items.push(exportItem);
      const exportItemDruck = {
        align: "Right",
        showTextOn: "Both",
        prefixIcon: "e-icon-schedule-print",
        text: "Print",
        cssClass: "e-print",
        click: this.onPrintIconClick.bind(this),
      };
      args.items.push(exportItemDruck);

      // const exportItemExcel = {
      //   align: "Right",
      //   showTextOn: "Both",
      //   prefixIcon: "e-icon-schedule-excel-export",
      //   text: "Excel Export",
      //   cssClass: "e-excel-export",
      //   click: this.onExportClickExcel.bind(this)
      // };
      // args.items.push(exportItemExcel);
    }

    console.log("schedule action: " + args.action, args);
    if (
      (args.requestType === "eventCreate" || args.requestType === "eventChange") &&
      args.data.length > 0
    ) {
      const eventData = args.data;
      if (eventData) {
        const schedule: any = this.getSchedule();
        const scheduleObj = schedule.ej2Instances;
        const startDate = eventData[0].StartTime;
        const endDate = eventData[0].EndTime;
        const groupIndex = 0;
        args.cancel = !scheduleObj.isSlotAvailable(
          startDate as Date,
          endDate as Date,
          groupIndex as number
        );
      }
    } else if (args.requestType === "eventRemove") {
      this.onDelete(args.data[0].aic_Bew_pool);
      args.cancel = true;
    }
  }
  protected navigating(args: any) {
    console.log("navigating", args);

    const schedule = this.$refs.schedule as ScheduleModel;
    const currentSelected: Date =
      schedule.selectedDate ?? this.zeitbereich?.von ?? new Date();
    // const currentView = schedule.currentView; // da steht der alte View vor dem Wechsel drinnen
    const currentView = args.currentView;
    if (!currentView) {
      this.currentView = this.activeView;
      let dDate: string | null = null;
      if (this.selectedDate) {
        dDate = this.selectedDate.toISOStringWithTZ();
        // wenn man von TimelineMonth auf TimelineWeek wechselt, dann setzt er das selected
        // Date nicht richtig.. im Current Date steht es aber sauber!
        if (dDate === this.lastDt) {
          dDate = args.currentDate;
        }
      }
      if (args.action === "date" && this.lastDt !== dDate) {
        this.$nextTick(() => {
          this.reloadNewZeitbereich();
          this.lastDt = dDate;
        });
      }
      return;
    }
    this.selectedDate = args.currentDate ?? this.selectedDate;
    if (
      currentView === "Day" ||
      currentView === "TimelineDay" ||
      currentView === "Week" ||
      currentView === "TimelineWeek" ||
      currentView === "WorkWeek"
    ) {
      const scheduleComp: any = this.getSchedule();
      const scheduleObj = scheduleComp.ej2Instances;
      // es soll nicht auf die Woche von HEUTE stellen sondern vom ZR aus wo ich bin!
      const startDate = currentSelected;
      // const startDate = scheduleObj.activeCellsData.startTime;
      this.selectedDate = startDate;
      this.activeView = currentView;
      if (currentView === "Week" && this.dienstplan) {
        this.startHour = "00:00";
        this.endHour = "24:00";
        this.timeScale = { interval: 60, slotCount: 1 };
      }
      if (currentView === "WorkWeek" && this.dienstplan) {
        this.startHour = "00:00";
        this.endHour = "24:00";
        this.timeScale = { interval: 60, slotCount: 1 };
      }
      if (currentView === "TimelineWeek" && this.dienstplan) {
        this.timeScale = { interval: 120, slotCount: 1 };
        // this.startHour = "08:00";
        // this.endHour = "20:00";
      }
    } else if (currentView === "Month" || currentView === "TimelineMonth") {
      if (this.selectedDate) {
        this.selectedDate = this.selectedDate.firstOfMonth();
      }
      this.activeView = currentView;
    } else if (currentView === "TimelineYear" || currentView === "Year") {
      if (this.selectedDate) {
        const date = this.selectedDate;
        const year = date.getFullYear();
        this.selectedDate = new Date(year, 0, 1);
      }
      this.activeView = currentView;
    }
    // this.activeView = currentView;
    // this.currentView = currentView;

    let dt: string | null = null;
    if (this.selectedDate) {
      dt = this.selectedDate.toISOStringWithTZ();
    }
    if ((args.action === "date" || args.action === "view") && this.lastDt !== dt) {
      this.$nextTick(() => {
        this.reloadNewZeitbereich();
        this.lastDt = dt;
      });
    } else if (
      args.currentView !== args.previousView &&
      args.previousView === "TimelineDay"
    ) {
      const pre = args.previousView;
      if (args.currentView === "WorkWeek") {
        this.currentView = "WorkWeek";
      }
      this.$nextTick(() => {
        this.reloadNewZeitbereich();
        this.lastDt = dt;
      });
    }
  }

  protected loadResources(gruppierung: boolean) {
    try {
      console.log("reloadData()");
      if (this.queryMitarbeiter == null || this.queryMitarbeiter === "") {
        console.log("keine Ressourcen geladen");
        return;
      }
      let query = this.queryMitarbeiter;
      if (gruppierung) {
        query = this.group2;
      }
      const vecStamm: any[] = [];
      const formular = this.formular?.aic ?? 0;
      const me = this;
      this.$api.getQuery(
        query,
        (employee: any) => me.fillUpResource(employee, gruppierung),
        this.queryAIC,
        this.zeitbereich,
        0,
        false,
        false,
        "0",
        formular,
        vecStamm
      );
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }

  protected reloadData() {
    if (this.refillRunning) {
      return;
    }
    this.refillRunning = true;
    try {
      console.log("reloadData()");
      if (this.query == null || this.query === "") {
        showSnackbar(
          this.$globalsBezeichnung(
            Globals.Begriff,
            "Web_keine_Abfrage",
            "keine Abfrage gefunden"
          )
        );
        this.showLoading = false;
        this.header = { bezeichnung: ";(" };
        return;
      }
      this.showSchedule = false;
      const me = this;
      me.showLoading = true;
      const vecStamm: any[] = [];
      this.$api.getQuery(
        // Query
        this.query,
        // Done function
        (data: any) => me.dataLoaded(data),
        this.queryAIC,
        this.zeitbereich,
        0,
        false,
        false,
        "0",
        this.formular.aic,
        vecStamm
      );
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }

  // Template setzen
  private editorTemplate() {
    console.log("editorTemplate()");
    return { template: editorTemplate };
  }
  private resourceHeaderTemplate(e: any) {
    return { template: resourceTemplate };
    // if (!Constants.isMobile) {
    //   return { template: resourceTemplate };
    // }
  }
  private takeCellTemplate(e: any) {
    if (this.useCellTemplate) {
      return { template: cellTemplate };
    }
  }

  private refillRunning: boolean = false;

  private fillUpResource(resource: any, gruppierung: boolean) {
    this.showText = false;
    try {
      const rowdata = resource.data.data;
      if (rowdata.length === 0) {
        this.schedule.group.resources = [];
        // schedule anzeigen, damit man den Zeitraum wechseln kann!
        this.showText = true;
        if (this.activeView !== "TimelineWeek") {
          // bei TimelineWeek kommt da ein Error...
          this.showGroupMutable = false;
        }
        // this.checked = false;
        const init = true;
        this.showAbteilung = false;
        this.groupBegriff = "";
        this.groupData = [];
        this.employee = [];
        this.eventSettings = {
          dataSource: [],
          enableTooltip: false,
          allowDeleting: this.allowDeleting,
        };
        this.refillRunning = false;
        this.initialized = true;
        this.showLoading = false;
        this.showSchedule = true;
        const resourceId = this.gotoAic;
        if (gruppierung) {
          showSnackbar("keine Daten für die Gruppierung gefunden");
        }
        this.$emit("loaded", init, resourceId);
      } else {
        const allColumns = resource.data.columns;
        if (gruppierung) {
          this.groupData = [];
          for (let i = 0; i < rowdata.length; i++) {
            try {
              const groupRow = rowdata[i];
              const favorit = getKZValue(groupRow, "favorit", allColumns);
              let color = "green";
              if (favorit) {
                color = "#edc266";
              }
              this.groupData.push({
                Text: getKZValueDisplay(groupRow, "text", allColumns),
                GroupID: getKZValue(groupRow, "id", allColumns),
                Color: color,
                Daten: 0,
              });
            } catch (e) {
              console.error(e);
            }
          }
          console.log("groupDatafertig");
          if (!this.dienstplan) {
            this.checked = true;
          }
          this.loadResources(false);
        } else {
          this.employee = [];
          const gruppe = allColumns.filter((c: any) => c.meta.gruppierbar === true);
          if (gruppe && gruppe.length > 0) {
            this.groupAic = gruppe[0].aic;
            if (gruppe[0].meta.pGruppiert !== undefined) {
              this.checked = gruppe[0].meta.pGruppiert;
            } else {
              if (!this.dienstplan) {
                this.checked = false;
              }
            }
            this.showAbteilung = this.checked;
            this.groupBegriff = resource.data.header.aicBegriff;
          }
          this.vector = [];
          for (let i = 0; i < rowdata.length; i++) {
            try {
              const employeeRow = rowdata[i];
              const cssClass = "cssAlle";
              let color = "transparent";
              if (!this.useCellTemplate) {
                color = "#fff";
                const extraColor = getKZValue(employeeRow, "color", allColumns);
                if (extraColor) {
                  color = extraColor;
                }
              }
              if (this.formular.pers) {
                if (i % 2 === 1) {
                  color = "rgb(194 203 237)";
                }
              }

              let zusatz = getKZValue(employeeRow, "ZusatzText", allColumns);
              if (zusatz) {
                zusatz = "(" + zusatz + ")";
              }
              const img = getKZColumn("icon", allColumns);
              let showImage = false;
              let showAvatar = false;
              let avatar;
              if (img) {
                avatar = getKZValueDisplay(employeeRow, "icon", allColumns);

                if (avatar !== "" && avatar) {
                  this.loadImage(avatar);
                  avatar = this.avatarIcon;
                  if (avatar === "mdi-account-circle") {
                    showAvatar = true;
                  } else {
                    showImage = true;
                  }
                } else {
                  showAvatar = true;
                  avatar = "mdi-account-circle";
                }
              }
              let sollzeitAnzeige = getKZValueDisplay(
                employeeRow,
                "Sollzeit",
                allColumns
              );
              if (sollzeitAnzeige) {
                const sollzeitText = getKZTitle(employeeRow, "Sollzeit", allColumns);
                sollzeitAnzeige = sollzeitText + " " + sollzeitAnzeige;
              }
              const arbeitszeitAnzeige = getKZValueDisplay(
                employeeRow,
                "Ist",
                allColumns
              );
              let urlaubAnzeige = "";
              let negativ = 0;
              if (arbeitszeitAnzeige) {
                const arbeitsText = getKZTitle(employeeRow, "Ist", allColumns);
                urlaubAnzeige = getKZValueDisplay(employeeRow, "Saldo", allColumns);
                const urlaub = getKZValue(employeeRow, "Saldo", allColumns);
                if (urlaub > 0) {
                  negativ = 1;
                }
                const titel = getKZTitle(employeeRow, "Saldo", allColumns);
                urlaubAnzeige = titel + ": " + urlaubAnzeige;
                sollzeitAnzeige =
                  sollzeitAnzeige + " " + arbeitsText + " " + arbeitszeitAnzeige;
              }
              let workDays = getKZValue(employeeRow, "workDays", allColumns);
              let startHour = getKZValue(employeeRow, "startHour", allColumns);
              if (!startHour) {
                startHour = "08:00";
              }
              let endHour = getKZValue(employeeRow, "endHour", allColumns);
              if (!endHour) {
                endHour = "20:00";
              }

              if (!workDays) {
                workDays = [0, 1, 2, 3, 4, 5, 6];
              } else {
                workDays = JSON.parse("[" + workDays + "]");
              }
              this.vector.push(employeeRow.aic_Stamm);
              this.employee.push({
                Text: getKZValueDisplay(employeeRow, "text", allColumns),
                GroupID: getKZValue(employeeRow, "id", allColumns),
                CssClass: cssClass,
                Color: color,
                groupIDField: getKZValue(employeeRow, "groupID", allColumns),
                Designation: sollzeitAnzeige,
                avatar,
                showImage,
                showAvatar,
                ZusatzText: zusatz,
                Urlaub: urlaubAnzeige,
                Daten: getKZValue(employeeRow, "count", allColumns),
                negativ,
                workDays,
                startHour,
                endHour,
              });
            } catch (e) {
              console.error(e);
            }
          }
          this.employeeOriginal = [...this.employee];
          console.log("Employee Data fertig");
        }
        if (this.employee.length > 0) {
          this.reloadData();
        }
      }
    } finally {
      // bin noch nicht fertig
      // this.refillRunning = false;
    }
  }
  private loadImage(avatar: any) {
    if (avatar.name) {
      this.avatarIcon = this.$api.fullUrl(
        "imageM/" + this.$api.user.id + ":" + +avatar.aic + "_" + avatar.name
      );
    } else {
      this.avatarIcon = "mdi-account-circle";
    }
  }
  private filterColumns: FilteredDataColumn[] | null = null;
  private dataLoaded(data: AxiosResponse<QueryResponse>) {
    // const schedule: any = this.$refs.schedule as ScheduleComponent;
    this.showSchedule = true;
    this.queryData = data.data;
    this.$nextTick(() => this.fillUpEvents(data));
  }

  private fillUpEvents(data: AxiosResponse<QueryResponse>) {
    try {
      const schedule: ScheduleComponent = this.getSchedule();
      if (!schedule) {
        this.$nextTick(() => this.fillUpEvents(data));
        return;
      }
      if (data.data.error !== undefined) {
        this.$api.onfail(data.data.error);
        console.log(data.data.error);
        this.$emit("back");
        alert("ACHTUNG: " + data.data.error);
        return;
      }
      this.header = data.data.header;
      // this.rowdata = data.data.data;
      this.allColumns = [...data.data.columns];
      // nur wenn es auch wirklich einen hinterlegten Filter gibt, darf ShowFilter aktiviert werden!
      const filterColumns: FilteredDataColumn[] = (this.filterColumns = this.allColumns.filter(
        (c: any) => c.meta.filterOK === true && !c.meta.invisible && !c.meta.banned
      ) as FilteredDataColumn[]);

      if (!filterColumns || filterColumns.length === 0) {
        this.showFilter = false;
      } else {
        this.showFilter = true;
      }
      if (this.header.edit === false) {
        this.readOnlyMutable = true;
        this.readOnly = true;
      }
      if (this.filteredData?.length > 0) {
        // nicht mehr neu füllen!
      } else {
        this.rowdata = [...data.data.data];
        this.eventSettings.dataSource.length = 0;
      }
      // this.eventSettings.dataSource.length = 0;
      this.fillEvents();
    } finally {
      // der gemerkte MA soll wieder gesetzt werden
      const schedule: any = this.getSchedule();
      if (schedule && (this.savedGroupIndex || this.gotoAic)) {
        if (this.gotoAic) {
          const group: any = this.employee.findIndex((obj: any) => {
            return obj.GroupID === this.gotoAic;
          });
          this.savedGroupIndex = group;
        }
        schedule.scrollToResource(this.gotoAic as string, "Employee");
        // hier sollte er auf den gemerkten t Index wieder zurück setzen..
        this.currentGroupIndex = schedule.ej2Instances.uiStateValues.groupIndex;
        if (this.savedGroupIndex !== this.currentGroupIndex) {
          // Restore the persisted groupIndex to the Schedule from global variable
          schedule.ej2Instances.uiStateValues.groupIndex = this.savedGroupIndex;
          // schedule.ej2Instances.refresh();
        }
      }
      // !schedule.scrollTo("12:00", new Date());
      this.showLoading = false;
      this.refillRunning = false;
      this.initialized = true;
      const init = true;
      const resourceId = this.gotoAic;
      this.$emit("loaded", init, resourceId);
    }
  }
  private fillEvents() {
    let startDate: any = null;
    if (this.rowdata.length > 0) {
      for (let i = 0; i < this.rowdata.length; i++) {
        try {
          const event = this.rowdata[i];
          // event.fields.recurrenceID = event.aic_Bew_pool;
          event.startDate = event.StartTime = getKZValue(event, "von", this.allColumns);
          if (!startDate) {
            startDate = new Date(event.StartTime);
          }
          event.EndTime = getKZValue(event, "bis", this.allColumns);
          if (!event.EndTime) {
            event.EndTime = event.startDate;
          }
          event.IsAllDay = false;
          event.IsReadonly = false;
          let allDay = getKZValue(event, "isAllDay", this.allColumns);
          const checkBewilligtNr = getKZValue(event, "Antrag", this.allColumns);
          const checkBewilligt = getKZValueDisplay(event, "Antrag", this.allColumns);
          const existStellvertreter = getKZValueDisplay(
            event,
            "location",
            this.allColumns
          ); // = Stellvertreter
          const checkStatusStellvertreterNr = getKZValue(
            event,
            "AntragSV",
            this.allColumns
          );
          const checkStatusStellvertreter = getKZValueDisplay(
            event,
            "AntragSV",
            this.allColumns
          );
          this.bewilligung = getKZValue(event, "zuBewilligen", this.allColumns);
          event.GroupID = getKZValue(event, "id", this.allColumns);
          // = AIC Mitarbeiter für Gruppierung zusammenfinden..??
          event.id = getKZValue(event, "groupID", this.allColumns);
          // Gruppierung nach Abteilung
          event.idField = event.GroupID;
          event.name = getKZValueDisplay(event, "name", this.allColumns);
          event.Status = getKZValue(event, "zuBewilligen", this.allColumns);
          const isReise = getKZValue(event, "reise", this.allColumns);
          let Subject = getKZValueDisplay(event, "subject", this.allColumns); // = Tagesmuster
          event.abwesenheit = false;
          if (!Subject) {
            Subject = getKZValueDisplay(event, "subject1", this.allColumns); // = Zeitart
            if (this.dienstplan) {
              // event.editorComponent = this.editorComponent1;
              event.abwesenheit = true; // an Dialog Maske übergeben welche Sicht aktiviert werden muss!
            }
          } else {
            const showBezeichnung = getKZTitle(event, "showBezeichnung", this.allColumns);
            if (!showBezeichnung) {
              if (!event.startDate) {
                let kennzeichen = getKZValueDisplay(
                  event,
                  "Kennzeichen",
                  this.allColumns
                );
                if (!kennzeichen) {
                  kennzeichen = "freier Tag";
                }
                allDay = true;
                Subject = kennzeichen;
                event.startDate = event.StartTime = getKennungValue(
                  event,
                  "VALIDITY",
                  this.allColumns
                );
                event.EndTime = event.startDate;
              } else {
                const beginn = new Date(event.startDate);
                const hours = beginn.toLocaleTimeString().substring(0, 5);
                const ende = new Date(event.EndTime);
                const endeString = ende.toLocaleTimeString().substring(0, 5);
                Subject = hours + " - " + endeString;
              }
              // Subject = " ";}
            }
          }
          event.IcsSubject = Subject;
          let abgelehnt = false;
          if (this.bewilligung && event.abwesenheit) {
            if (checkBewilligtNr === 1 || checkBewilligtNr === true) {
              this.htmText = "&#10003";
              if (!this.readBewilligt) {
                event.IsReadonly = true;
              }
            } else if (checkBewilligtNr === 2) {
              this.htmText = "x";
              abgelehnt = true;
            } else if (checkBewilligtNr === 0 || checkBewilligtNr === false) {
              this.offen = true;
              this.htmText = "&#9632";
            }
          } else if (checkBewilligtNr && event.abwesenheit) {
            if (checkBewilligtNr === 1) {
              this.htmText = "&#10003";
            } else if (checkBewilligtNr === 0) {
              this.htmText = "offen";
            } else if (checkBewilligtNr === 2) {
              this.htmText = "x";
            }
          } else if (isReise) {
            const status = getKZValueDisplay(event, "status", this.allColumns);
            this.htmText = status;
            event.IsReadonly = getKZValue(event, "readOnly", this.allColumns);
          } else {
            this.htmText = "";
          }
          if (existStellvertreter && event.abwesenheit) {
            if (checkStatusStellvertreterNr === 1) {
              this.htmText = this.htmText + "&#9474" + "&#10003";
            } else if (checkStatusStellvertreterNr === 2) {
              this.htmText = this.htmText + "&#9474" + "x";
            } else if (checkStatusStellvertreterNr === 0) {
              this.htmText = this.htmText + "&#9474" + "&#9632";
            } else if (checkStatusStellvertreterNr) {
              this.htmText = this.htmText + "&#9474" + "&#10003";
            }
          }
          if (allDay === 1 || allDay === true) {
            event.IsAllDay = true;
            event.isBlock = true;
          }
          event.editorComponent = this.editorComponent;
          const abwText = getKZValue(event, "abwText", this.allColumns);
          // bei verschleierten Zeitarten soll "abwesend" stehen
          if (abwText) {
            const showText = getKZTitle(event, "abwText", this.allColumns);
            event.Subject = this.htmText + " " + showText;
          } else if (this.htmText) {
            // event.Subject = Subject;
            event.Subject = this.htmText + " " + Subject;
          } else {
            event.Subject = Subject;
          }
          if (this.htmText) {
            this.Subject = this.htmText + " " + event.Subject;
          } else {
            this.Subject = event.Subject;
          }
          event.Description = getKZValueDisplay(event, "description", this.allColumns);
          event.description = getKZValueDisplay(event, "description", this.allColumns);
          event.Description2 = getKZValueDisplay(event, "VG", this.allColumns);
          event.Location = getKZValueDisplay(event, "location", this.allColumns);
          // if (event.Description && event.Location) {
          //   event.Location = event.Location + "<br>" + event.Description;
          // } else if (event.Description) {
          //   event.Location = event.Description;
          // }
          event.LocationText = getKZTitle(event, "location", this.allColumns);
          if (this.dienstplan) {
            event.dienstplan = true;
          }
          const eventreadOnly = getKZValue(event, "isReadOnly", this.allColumns);
          if (eventreadOnly === true) {
            event.IsReadonly = true;
          }
          const edit = getKZValue(event, "edit", this.allColumns);
          if (edit === false) {
            event.IsReadonly = true;
          }
          event.exportICS = getKZValue(event, "export", this.allColumns);
          // ob die Zeitart exportiert werden soll oder nicht"
          event.IsBlock = getKZValue(event, "isBlock", this.allColumns);
          if (event.IsBlock) {
            // bei isBlock wird ein Tag zuviel angezeigt! statt einem Tag 2
            // const bis = new Date(event.EndTime).addDays(-1);
            const bis = new Date(event.EndTime);
            event.EndTime = bis.toISOStringWithTZ();
            const text = getKZTitle(event, "isBlock", this.allColumns);
            event.Subject = text;
          }
          if (this.sperrDate) {
            if (event.StartTime < this.sperrDate) {
              event.IsReadonly = true;
            }
          }
          event.Spanned = false;
          const Spanned = getKZValue(event, "Spanned", this.allColumns);
          if (Spanned === true) {
            event.Spanned = true;
          }
          event.location = getKZValueDisplay(event, "location", this.allColumns);
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      // gibt es keine Daten kommt ein error
      // wenn es kein StartDate gibt bei gruppierten Daten
    }
    const me: any = this;
    // 1) tooltip setzen
    if (this.rowdata.length > 0) {
      if (this.currentView !== "Year" && !this.abteilung) {
        this.eventSettings = {
          dataSource: this.rowdata,
          allowDeleting: this.allowDeleting,
          tooltipTemplate(param: any) {
            const VueTooltipTemplate: any = Vue.extend(
              me.$options.components.TooltipTemplate
            );
            // console.log("bin im Template" + VueTooltipTemplate);
            VueTooltipTemplate.schedule = me.schedule;
            return {
              template: VueTooltipTemplate,
            };
          },
          enableTooltip: true,
          fields: {
            location: {
              title: "",
              name: "Location",
              default: "",
            },
            description: { title: "", name: "Description", default: "" },
          },
        };
      } else if (this.currentView === "Year" || this.abteilung) {
        this.eventSettings = {
          dataSource: this.rowdata,
          enableTooltip: false,
          allowDeleting: this.allowDeleting,
          fields: {
            location: {
              title: "",
              name: "location",
              default: "",
            },
            description: { title: "", name: "description", default: "" },
          },
        };
      }
    }
    // 2) Group Setzen
    if (this.group2) {
      this.group = {
        enableCompactView: true,
        resources: ["GroupData", "Employee"],
      };
    } else if (
      this.queryMitarbeiter !== "" ||
      (this.employee && this.employee.length > 0)
    ) {
      this.group = {
        enableCompactView: true,
        resources: ["Employee"],
      };
    } else {
      this.group = {
        enableCompactView: true,
        resources: [],
      };
    }
  }
  private backDelete() {
    this.showDialog = false;
    this.$nextTick(() => {
      setTimeout(() => this.reloadData(), 100);
    });
  }
  private dialogBackonSave() {
    this.showDialog = false;
    // mit Timeout umgehe ich aktuell das ladeProblem -aber ich verstehs nicht...
    if (updateData) {
      const daten = updateData;
      // this.reloadData();
      if (!this.dienstplan) {
        this.$nextTick(() => {
          setTimeout(() => this.reloadData(), 100);
        });
      } else {
        this.loadResources(true);
      }
      // beim DP muss ich auch die MA Abfrage neu laden damit Soll/Ist richtig angezeigt wird
    }
  }
  private dialogBack(updatedata: any) {
    // wenn nur geschlossen wird - ohne Änderung nicht refreshen
    this.showDialog = false;
    if (updatedata) {
      this.$nextTick(() => {
        setTimeout(() => this.reloadData(), 100);
      });
    }
  }
  private setTGM(selected: any) {
    if (selected) {
      this.dialog.selectedTGM = selected.aic;
    }
  }
  private changeGroup(change: boolean) {
    this.checked = true;
    const schedule = this.getSchedule();
    this.showAbteilung = true;
    schedule.dataBind();
  }
  private onEventRendered(args: any) {
    // console.log("onEventRendered()");
    const event = args.data;
    if (!event.IsBlock) {
      // wenn geblockt - dann keine Farbe
      let categoryColor = getKennungValue(event, "FARBE", this.allColumns);
      // gibt es keine Farben (zB Bei Reisen) dann soll der bewilligt Status die farbe setzen
      if (categoryColor === "" || !categoryColor) {
        categoryColor = getKennungValue(event, "Farbe1", this.allColumns);
        if (categoryColor === "" || !categoryColor) {
          const checkBewilligt = getKZValue(args.data, "Antrag", this.allColumns);
          const abgelehnt = getKZValue(args.data, "abgelehnt", this.allColumns);
          if (abgelehnt) {
            categoryColor = "#EED0D0"; // rot
          } else if (checkBewilligt) {
            categoryColor = "#bbdc00"; // grün
          } else if (!this.useCellTemplate) {
            categoryColor = "grey";
          }
        }
      }
      // wenn verschleiert dann grau
      const verschleiern = getKZValue(event, "abwText", this.allColumns);
      if (!args.element || !categoryColor) {
        return;
      }
      if (verschleiern) {
        args.element.style.backgroundColor = "grey";
      } else {
        args.element.style.backgroundColor = categoryColor;
      }
    }
    if (this.isReadOnly(args.data)) {
      args.element.classList.add("e-past-app");
    }
  }
  private getFeiertag(value: any) {
    const holiday = this.$api.getHoliday(value);

    if (holiday) {
      const text = holiday.bezeichnung;
      return text;
    }

    return "";
  }
  private onRenderCell(args: any) {
    const scheduleObj = this.$refs.schedule;

    if (
      (this.activeView === "TimelineWeek" &&
        args.elementType === "dateHeader" &&
        this.dienstplan) ||
      (args.element.classList.contains("e-time-slots") && this.dienstplan)
    ) {
      if (args.date.getDate() % 2 !== 0) {
        args.element.style.backgroundColor = "grey";
      }
    }
    if (args.elementType === "workCells") {
      const groupIndex = args.groupIndex;
      if (groupIndex % 2 === 0) {
        if (this.activeView === "WorkWeek" || this.activeView === "Week") {
          args.element.classList.add("custom-first-group");
        } else {
          args.element.style.backgroundColor = "#e4f3f8";
        }
      } else {
        if (this.activeView === "WorkWeek" || this.activeView === "Week") {
          args.element.classList.add("custom-second-group");
        } else {
          args.element.style.backgroundColor = "#def8da";
        }
      }
    }
    if (
      this.currentView === "TimelineMonth" &&
      (args.elementType === "workCells" || args.elementType === "monthCells")
    ) {
      const weekEnds = [0, 6];
      if (weekEnds.indexOf(args.date.getDay()) >= 0) {
        const ele = createElement("div", {
          className: "weekend",
        });
        args.element.appendChild(ele);
      }
    }
    if (args.elementType === "monthCells" && this.currentView === "Month") {
      const ele = document.createElement("span");
      ele.className = "templatewrap";
      ele.style.cssText = "color: red;padding-left:5px;";
      ele.innerHTML = this.getFeiertag(args.date);
      if (Constants.isMobile) {
        ele.innerHTML = ele.innerHTML.slice(0, 5);
      }

      if (ele.innerHTML) {
        const container = args.element as HTMLElement;
        if (container.children.length > 0) {
          container.children[0].appendChild(ele);
        } else {
          container.appendChild(ele);
        }
      }
      //   if (args.date.getDay() === 0 || args.date.getDay() === 6) {
      //    args.element.bgColor = "green";
      // }
    } else if (args.elementType === "dateHeader") {
      if (this.currentView === "TimelineMonth" || this.activeView === "TimelineWeek") {
        const ele = document.createElement("span");
        ele.className = "templatewrap";
        const Feiertag = this.getFeiertag(args.date);
        if (Feiertag) {
          ele.style.cssText = "color: red;";
        } else {
          ele.style.cssText = "color: black;";
        }
        if (this.currentView === "TimelineMonth" && this.activeView !== "TimelineWeek") {
          const days = getWeekdayNamesShort();
          const day = args.date;
          const weekday = days[day.getDay()];
          if (Feiertag) {
            const FeiertagText = Feiertag.slice(0, 5);
            ele.innerHTML = FeiertagText + " " + weekday + ".";
          } else {
            ele.innerHTML = weekday + ".";
          }

          if (ele.innerHTML) {
            const container = args.element as HTMLElement;
            container.insertBefore(ele, container.children[0]);
          }
        }
      }
    }
    if (args.date && args.element && this.activeView === "TimelineWeek") {
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      args.element.classList.add("e-" + days[args.date.getDay()]);
    }
    if (args.elementType === "resourceHeader") {
      const schedule = this.$refs.schedule as ScheduleComponent;

      const resourceDetails: any = schedule.getResourcesByIndex(args.groupIndex);

      const groupIndex = args.groupIndex;
      // if (groupIndex % 2 === 0) {
      //   args.element.style.backgroundColor = "#e4f3f8";
      // } else {
      //   args.element.style.backgroundColor = "#def8da";
      // }
      if (args.element.classList.contains("e-parent-node")) {
        if (resourceDetails.resource.colorField) {
          args.element.style.backgroundColor =
            resourceDetails.resourceData[resourceDetails.resource.colorField];
        } else if (groupIndex % 2 === 0) {
          args.element.style.backgroundColor = "#e4f3f8";
        } else {
          args.element.style.backgroundColor = "#def8da";
        }
      } else {
        if (groupIndex % 2 === 0) {
          args.element.style.backgroundColor = "#e4f3f8";
        } else {
          args.element.style.backgroundColor = "#def8da";
        }
      }

      // if (this.employee) {
      //   args.element.style.background = "transparent";
      // }
    }
    // else if (args.elementType === "resourceHeader" && this.useCellTemplate) {
    //   if (this.employee) {
    //     const schedule = this.$refs.schedule as ScheduleComponent;
    //     if (args.element.innerText === this.$api.user.bezeichnung) {
    //       args.element.style.backgroundColor = "#E6E6E6";
    //     }
    //   }
    // }
  }

  private onPopupOpen(args: OurPopupOpenEventArgs) {
    console.log("onPopupOpen()", args);
    const entry = args.data as CalendarEntry;
    const dtBeginn = entry.date ?? args.date ?? entry.StartTime.toISOStringWithTZ();

    // bewilligt Status nur prüfen wenn es ein gespeicherter Satz ist
    // const dtBeginn = args.data.StartTime.toISOStringWithTZ();
    if (entry.aic_Bew_pool || entry.aic_Stamm) {
      const checkBewilligt = getKZValueDisplay(args.data, "Antrag", this.allColumns);
      const checkBewilligtNr = getKZValue(args.data, "Antrag", this.allColumns);
      this.bewilligung = getKZValue(args.data, "zuBewilligen", this.allColumns);
      this.bewilligt = false;

      if (!this.readBewilligt) {
        if (this.bewilligung) {
          if (checkBewilligtNr === "1") {
            this.bewilligt = true;
            entry.IsReadonly = true;
          }
        }
      }
      if (dtBeginn < this.sperrDate) {
        entry.IsReadonly = true;
      }
    } else if (!this.showGroupMutable) {
      const dateCheck = this.checkDateRange(
        entry.StartTime,
        entry.EndTime,
        entry.aic_Bew_pool
      );
      if (!dateCheck) {
        console.log("intersect line 921");

        entry.IsReadonly = true;
        args.cancel = true;
        return;
      }
    }
    if (this.sperrDate) {
      const startSperrDate = new Date(this.sperrDate).addMonths(-60, false);
      if (dtBeginn < this.sperrDate) {
        entry.IsReadonly = true;
        args.cancel = true;
        this.readBewilligt = false;
        this.showQuickInfoMutable = true;
        this.showDialog = false;
        showSnackbar({
          text: this.$globalsBegriffMemo(
            Globals.Begriff,
            "Periodensperre",
            "Zeitraum gesperrt!"
          ),
          duration: 2000,
          color: "error",
        });
        // return;
      }
    }
    if (this.onlyEdit && !args.cancel) {
      if (args.type === "QuickInfo") {
        // schaltet delete Button in der QuickInfo aus!
        const deleteEle = args.element.querySelector(".e-event-popup .e-delete");
        if (deleteEle) {
          (deleteEle as any).ej2_instances[0].disabled = true;
        }
      }
      if ((args.type === "QuickInfo" || args.type === "Editor") && !entry.Subject) {
        entry.IsReadonly = true;
        args.cancel = true;
        this.readBewilligt = false;
        this.showQuickInfoMutable = true;
        this.showDialog = false;
      }
    }
    if (!this.readBewilligt) {
      if (
        args.type === "QuickInfo" &&
        (this.bewilligt || entry.IsReadonly || this.readOnly)
        // (this.bewilligt || args.data.IsReadonly || this.readOnlyMutable)
      ) {
        this.showQuickInfoMutable = true;
        entry.IsReadonly = true;
        this.showDialog = false;
        if (entry.Description) {
          const rowDescription = createElement("div", {
            className: "e-description",
          });
          const iconDiv = createElement("div", {
            className: "e-description-icon e-icons",
          });
          const Description = createElement("div", {
            className: "e-description-details",
          });
          Description.innerText = entry.Description;
          rowDescription.appendChild(iconDiv);
          rowDescription.appendChild(Description);
          const popupElement = args.element.querySelector(".e-popup-content");
          popupElement?.appendChild(rowDescription);
        }
        if (entry.Location) {
          const rowLocation = createElement("div", {
            className: "e-description",
          });
          const iconDiv = createElement("div", {
            className: "e-resource-icon e-icons",
          });
          const Stellverterter = createElement("div", {
            className: "e-description-details",
          });
          Stellverterter.innerText = entry.Location;
          rowLocation.appendChild(iconDiv);
          rowLocation.appendChild(Stellverterter);
          const popupElement = args.element.querySelector(".e-popup-content");
          popupElement?.appendChild(rowLocation);
        }
        if (entry.Description2) {
          const rowDes = createElement("div", { className: "e-description" });
          const iconDiv = createElement("div", {
            className: "e-description-icon e-icons",
          });
          const Description2 = createElement("div", {
            className: "e-description-details",
          });
          Description2.innerText = entry.Description2;
          rowDes.appendChild(iconDiv);
          rowDes.appendChild(Description2);
          const popupElement = args.element.querySelector(".e-popup-content");
          popupElement?.appendChild(rowDes);
        }
      }
    }
    if (args.type === "QuickInfo" && !this.bewilligt && entry.IsReadonly) {
      if (Constants.isMobile) {
        if (this.onlyEdit || this.readOnlyMutable) {
          this.allowDeleting = false;
        }
      }
      if (!Constants.isMobile) {
        console.log("cancel on NOT mobile ");
        args.cancel = this.showQuickInfoMutable; // laut Forum kann man am Handy die Quickinfo NICHT ausschalten!
      }
    } else if (args.type === "Editor" || args.type === "ViewEventInfo") {
      if (!entry.IsReadonly && this.editorComponent) {
        args.cancel = true;
        let startTime = null;
        let endTime = null;
        let IsAllDay = false;
        let insertHint = false;
        if (entry.aic_Bew_pool || entry.aic_Stamm) {
          startTime = getKZValue(args.data, "von", this.allColumns);
          endTime = getKZValue(args.data, "bis", this.allColumns);
          if (!endTime) {
            endTime = startTime;
          }
          IsAllDay = getKZValue(args.data, "isAllDay", this.allColumns);
          insertHint = false;
        } else {
          insertHint = true;
          if (entry.startTime) {
            startTime = entry.startTime;
          } else if (entry.StartTime) {
            startTime = entry.StartTime;
          }
          if (entry.endTime) {
            endTime = entry.endTime;
          } else if (entry.EndTime) {
            endTime = entry.EndTime;
          }
        }
        const dialogZeitbereich: APIZeitbereich = {
          bereich: "Tag",
          von: new Date(startTime),
          bis: new Date(endTime),
        }; // damit zeitraum stimmt
        if (!entry.aic_Bew_pool && !entry.aic_Stamm) {
          if (this.onlyEdit) {
            return;
          }
          dialogZeitbereich.bis = new Date(endTime).addDays(1); // damit zeitraum stimmt.
        }
        // für den Dialog setzen
        this.dialog.zeitbereich = dialogZeitbereich;
        this.dialog.IsAllDay = IsAllDay;
        this.dialog.insertMode = insertHint;
        if (entry.GroupID && !this.dialogAicStamm) {
          // dialaogAIcStamm = AIC vom EVENT nehmen (Reisekosten)
          this.dialog.aic = entry.GroupID;
          this.gotoAic = this.dialog.aic; // AIC merken - um nach dem schliessen wieder am richtigen zu stehen!
        } else if (entry.aic_Stamm) {
          this.dialog.aic = entry.aic_Stamm;
          this.gotoAic = entry.GroupID;
          // wenn aus dem Dialog noch weiter auf eine Submaske
          // gegangen wird - brauch ich den MA (Zb von den Reisen zu den Buchungen)
          this.dialog.groupID = entry.GroupID;
          this.dialog.grouName = entry.name;
        }
        this.dialog.abwesenheit = entry.abwesenheit;
        if (entry.editorComponent) {
          this.dialog.editorComponent = entry.editorComponent;
        } else {
          this.dialog.editorComponent = this.editorComponent;
        }
        this.showDialog = true;
        console.log(
          "showing dialog",
          // this.editorComponent,
          this.dialog,
          dialogZeitbereich
        );
        args.cancel = true;
        return;
      }
    } else if (args.requestType === "toolbarItemRendering" && this.dienstplan) {
      const exportItem = {
        align: "Right",
        showTextOn: "Both",
        prefixIcon: "e-icon-schedule-print",
        text: "Print",
        cssClass: "e-print",
        click: this.onPrintIconClick.bind(this),
      };
    }
  }
  private onPrintIconClick() {
    console.log("onPrintIconClick()");
    const schedule = this.getSchedule();
    const printOptions = {
      height: "auto",
      width: "100%",
      cssClass: "e-print-schedule",
      currentView: this.currentView,
      rowAutoHeight: false,
      showHeaderBar: false,
      showTimeIndicator: false,
      showWeekNumber: false,
      showWeekend: false,
    };
    // schedule.exportToExcel();
    const height = this.myHeight;
    printVueElement(schedule.$el as HTMLElement, "Dienstplan");
    // does not work schedule.print(printOptions);
  }

  private getSchedule(): ScheduleComponent {
    return (this.$refs.schedule as any) as ScheduleComponent;
  }
  private onExportClickExcel() {
    const schedule = this.getSchedule();
    schedule.exportToExcel();
  }
  private onClick() {
    const schedule = this.getSchedule();
    const scheduleObj = schedule.ej2Instances;
    const customData: any[] = [];
    const heute = new Date();
    let dates = [];
    if (schedule) {
      dates = schedule.getCurrentViewDates() as any[];
    }
    const bisZeitraum = new Date(Math.max.apply(null, dates)); // soll der letzte Tag der ANGEZEIGT wird sein!
    // const bisZeitraum = this.zeitbereich?.bis;
    for (let i = 0; i < this.eventSettings.dataSource.length; i++) {
      const rowData = this.eventSettings.dataSource[i];
      const subject = rowData.IcsSubject;
      const icsExport = rowData.exportICS;
      const startTime = new Date(rowData.startDate);
      const endTime = new Date(rowData.EndTime);
      const isAllDay = rowData.IsAllDay;
      // if (isAllDay) {
      //   endTime = endTime.addDays(-1);
      // }
      const categoryColor = rowData.CategoryColor;
      const description = rowData.Description;
      const location = rowData.location;
      const readOnly = rowData.readOnly;
      const UID = rowData.aic_Bew_pool;
      // ab Heute und bis zum BIS des Zeitraums... nicht weiter in die Zukunft...
      if (icsExport && startTime >= heute && bisZeitraum && startTime < bisZeitraum) {
        customData.push({
          Subject: subject,
          StartTime: startTime,
          EndTime: endTime,
          DESCRIPTION: description,
          CategoryColor: categoryColor,
          ISREADONLY: readOnly,
          LOCATION: location,
          UID,
        });
      }
    }
    if (customData?.length > 0) {
      scheduleObj.exportToICalendar("calendar", customData);
    } else {
      showSnackbar({
        text: this.$globalsBegriffMemo(
          Globals.Begriff,
          "ICS_Export_leer",
          "Es wurden keine Daten für den ICS Export gefunden"
        ),
        color: "info",
      });
    }
  }

  protected onDelete(aic: any, goBackOnSuccess: boolean = true) {
    this.showDialog = false;
    const bewPool = aic ?? this.dialog.aic;
    if (!bewPool) {
      showSnackbar("keinen Datensatz übergeben!");
      return;
    }
    const aicAbfAllBez = this.$globalsKennung(Globals.Begriff, "WebPlanungSpeichern");
    if (!aicAbfAllBez) {
      showSnackbar({
        text: "Abfrage nicht gefunden",
        color: "error",
      });
      this.showLoading = false;
      return;
    }
    const aicAbfAll = aicAbfAllBez.aic;
    this.$api.DeleteData(
      this.header,
      bewPool,
      aicAbfAll,
      this.zeitbereich,
      (res: any) => {
        const msgType = res.data.msgType;
        let info = res.data.info;
        const memo = res.data.memo;
        const title = res.data.titel;
        if (memo) {
          info = memo;
        }

        if (info) {
          if (msgType === "Error") {
            showSnackbar({
              text: info,
              color: "error",
            });
          } else if (msgType === "Info") {
            showSnackbar({
              text: info,
              color: "info",
            });
          } else if (msgType === "Warnung") {
            showSnackbar({
              text: info,
              color: "warning",
            });
          }
          this.showLoading = false;
          return msgType;
        }

        showSnackbar(
          this.$globalsBegriffMemo(
            Globals.Begriff,
            "Datensatz_geloescht",
            "Daten wurden gelöscht"
          )
        ); // Geloescht
        if (goBackOnSuccess) {
          this.$emit("back", true);
        }
        this.reloadData();
      },
      (ex: any) => {
        if (ex && ex.response) {
          let errorMessage = "";
          if (ex.response) {
            errorMessage = ex.response.data.error;
          } else {
            errorMessage = ex;
          }
          this.$root.$emit("alert", {
            text: errorMessage,
            type: "error",
          });
        }
        console.log(ex);
        if (ex !== this.$api.ApiBusyMessage) {
          showSnackbar(
            this.$globalsBegriffMemo(
              Globals.Begriff,
              "Berechnung_lauft",
              "Berechnung läuft, bitte warten Sie diese ab."
            )
          );
        }
        this.reloadData();
      }
    );
    this.reloadData();
  }
  private onChange(args: any) {
    const schedule = this.getSchedule();
    this.checked = args.checked;
    if (args.checked) {
      this.showAbteilung = true;
      // schedule.group.resources = ["Employee"]
      // schedule.addResource(this.groupData[0], "GroupData", value - 1);
      schedule.dataBind();
    } else {
      this.showAbteilung = false;
      // schedule.removeResource(value, "GroupData");
      schedule.dataBind();
    }
  }
  private filteredDataChanged(filteredData: FilteredQueryResponse) {
    if (this.showFilter) {
      this.filteredData = filteredData.data;
      const data: any[] = filteredData.data;
      if (data && this.allColumns?.length > 0) {
        const me = this;
        const colls = me.allColumns;
        const searchText = (this.$refs.baseFilter as BaseFilter)
          ?.getSearchText()
          ?.toLowerCase();
        const filterEmployee = this.employeeOriginal.filter((e) => {
          const inData =
            filteredData.data.findIndex(
              (d) => getKZValue(d, "id", colls) === e.GroupID
            ) >= 0;
          const inText =
            !searchText || (e.Text?.toLowerCase().indexOf(searchText) ?? -1) >= 0;
          // wenn KEIN Text erfasst ist - aber filter ACTIVE gesetzt ist - dann nur die Zeilen MIT Daten!
          // in DIenstplan sollen vorab ALLE MA angezeigt werden,
          // aber wenn ein Filter gesetzt wird dann nur MA mit diesen Daten!
          if (filteredData.filterSet) {
            filteredData.filterActive = true;
          }
          if (filteredData.filterActive) {
            return inData || (inText && searchText);
          } else {
            return inData || !searchText || (inText && searchText);
          }
        });
        this.employee = filteredData.filterActive
          ? filterEmployee
          : this.employeeOriginal;
      }
      this.rowdata = filteredData.data;
      this.eventSettings.dataSource.length = 0;
      this.fillEvents();
    }
  }
  private onReloadData() {
    this.loadResources(true);
  }
  private changeView(args: any) {
    const schedule = this.getSchedule();
    if (
      args.element.classList.contains("e-header-cells") &&
      this.activeView === "TimelineWeek"
    ) {
      this.activeView = "TimelineDay";
      this.selectedDate = args.startTime;
      this.navigating(args);
    } else {
      this.onPopupOpen(args);
    }
  }
  // private getDateHeaderDate(value: any) {
  //   const instance = new Internationalization();
  //   return instance.formatDate(value, { skeleton: "d" });
  // }
  // private navigateToDayView(clickedDate: any) {
  //   const dateClick = new Date(clickedDate);
  //   this.currentView = "TimelineDay";
  //   this.selectedDate = dateClick;
  //   this.navigating(clickedDate);
  // }
}
