<template>
  <v-card raised width="90%">
    <BaseVGrid
      query="Web_RK_ExportBuchungsbeleg"
      :zeitbereich="zeitbereich"
      :queryAic="queryAIC"
      :showClose="false"
    ></BaseVGrid>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      aic: 0,
    };
  },
};
</script>

<style scoped></style>
