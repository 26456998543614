
















































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import EditComponent from "@/editor/EditComponent";
import { getKennungValueDisplay, getKennungTitle } from "@/DataHelper";
import { showSnackbar, scrollIntoView } from "@/UIHelper";

@Component
export default class DienstReiseBeginn extends EditPage {
  private ort: string = "";
  private zielort: string = "";
  private selectFahrzeug: boolean = false;
  private fahrtkosten: boolean = false;
  constructor() {
    super();
    // this.query = "Web_DRBeginn_ReiseBeginn";
    this.query = "Web_RK_DienstreiseBeginn";
  }
  public mounted() {
    this.mountedBase();
  }
  protected initialize() {
    if (this.data) {
      this.ort = getKennungValueDisplay(this.data, "RK_ABFAHRTSORT", this.columns);
      this.selectFahrzeug = getKennungValueDisplay(
        this.data,
        "RK_TRANSPORTMITTEL",
        this.columns
      );
      const ereignis = getKennungValueDisplay(
        this.data,
        "RK_REISEEREIGNISSE",
        this.columns
      );
      if (!ereignis) {
        const meldung =
          "Erfassung der Reise nicht möglich, nachfolgende Reisen wurden bereits freigegeben!";
        showSnackbar({
          text: meldung,
          color: "warning",
          duration: 4000,
          closedManual: true,
        });
        this.$emit("back");
      } else if (!this.ort) {
        const meldung =
          "Es wurde kein Dienstort erfasst - bitte ergänzen Sie diese Daten!";
        showSnackbar({
          text: meldung,
          color: "warning",
        });
      }
    } else {
      const meldung =
        "Erfassung der Reise nicht möglich, nachfolgende Reisen wurden bereits freigegeben!";
      showSnackbar({
        text: meldung,
        duration: 4000,
        color: "warning",
      });
      this.$emit("back");
    }
  }

  public save() {
    let varUebergabe = [];
    if (this.siteData.varUbergabe) {
      varUebergabe = this.siteData.varUbergabe;
    }
    let ortText = "";
    if (this.ort && this.zielort) {
      const aicAbfAll = 0;
      const aic: string = "0";
      const me = this;
      this.onSave(aicAbfAll, "", "", aic, true, varUebergabe)?.then(() => {
        this.$nextTick(() => me.$emit("save"));
      });
      // this.onSave(0,this.data, "");
    } else {
      if (!this.zielort) {
        ortText = getKennungTitle("CITY", this.columns);
      } else {
        ortText = getKennungTitle("RK_ABFAHRTSORT", this.columns);
      }
      let meldung = this.$globalsBegriffMemo(
        this.Globals.Begriff,
        "WEB_SpeichernNichtMoeglich",
        "Speichern kann nicht durchgeführt werden da folgende Daten leer sind"
      );
      meldung = meldung + ": " + ortText;
      showSnackbar({
        text: meldung,
        color: "warning",
      });
    }
  }
  private checkValue(evt: any) {
    this.fahrtkosten = evt.value.setShow;
  }
  private scrollMeIntoView(e: Event) {
    scrollIntoView(e);
  }
}
