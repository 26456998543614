
































































import { Component, Prop, Watch } from "vue-property-decorator";

import AUApi from "@/api";
import {
  getDisplay,
  getEinheitFromColumn,
  getKZColumn,
  getKZColumnsByPrefix,
  getValue,
  roundNumber,
  getKZValueDisplay,
} from "@/DataHelper";
import Chartist from "chartist";
import ChartistTooltips from "chartist-plugin-tooltips-updated";
import "chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.css";
import { getAlternateColor } from "../ChartTypesAndFunctions";
import ChartBase from "./ChartBase";
import BaseQueryChart from "./BaseQueryChart";
import { printVueElement, showSnackbar } from "@/UIHelper";
import { TooltipPlugin } from "@syncfusion/ej2-vue-popups";

declare interface ChartData {
  aic: number;
  labels: string[];
  series: ChartSeriesData[][] | ChartSeriesData[];
  name?: string;
}

declare interface MetaInfo {
  aic: number;
  value: number;
  color?: string;
  min?: number;
  max?: number;
  tooltip?: string;
}

declare interface ChartSeriesData {
  meta: MetaInfo;
  value: number;
}

function getChartFromElement(el: any, predicate: (x: any) => boolean) {
  let vue: Vue | undefined;
  while (el) {
    if (el.__vue__) {
      vue = el.__vue__;
      break;
    } else {
      el = el.parentNode;
    }
  }

  while (vue) {
    if (predicate(vue)) {
      return vue;
    }
    vue = vue.$parent;
  }

  return undefined;
}

@Component
export default class CChart extends BaseQueryChart {
  @Prop({ default: "series" }) public seriesColumnKZ!: string;
  @Prop({ default: "labelBez" }) public labelsColumnKZ!: string;

  private minColumn?: DataColumn;
  private maxColumn?: DataColumn;
  private minColor: string = "orange";
  private maxColor: string = "red";
  private min: number = 0;
  private max: number = 10000;
  private periodisch: boolean = false;

  public mounted() {
    this.refresh();
  }

  private tooltipPlugin: any;
  public setOptions() {
    if (this.chartType === "Pie") {
      this.tooltipPlugin = ChartistTooltips({
        appendToBody: true,
        // anchorToPoint: true,
        pointClass: "ct-slice-pie",
        // pointClass: 'my-cool-point'
        // transformTooltipTextFnc: this.transformTooltipTextFunction,
        tooltipFnc: this.transformTooltipTextFunction,
      });
    } else {
      this.tooltipPlugin = ChartistTooltips({
        appendToBody: true,
        // anchorToPoint: true,
        pointClass: "ct-bar",
        // pointClass: 'my-cool-point'
        // transformTooltipTextFnc: this.transformTooltipTextFunction,
        tooltipFnc: this.transformTooltipTextFunction,
      });
    }
    this.options = {
      // width: "100%",
      // height: "100%",
      aspectRatio: "",
      axisX: {
        // The offset of the chart drawing area to the border of the container
        // offset: 50,
      },
      axisY: {
        // The offset of the chart drawing area to the border of the container
        offset: 180,
        // scaleMinSpace: 30,
      },
      seriesBarDistance: 60,
      plugins: [this.tooltipPlugin],
    };
  }

  private get chartstChartType() {
    return this.chartType === "BalkenStackedMinMax" ? "Bar" : this.chartType;
  }

  public get chartType() {
    return this.params.chartType ?? "Bar";
  }
  public get chartOrientation() {
    return this.params.chartOrientation;
  }
  @Prop({ default: 10 }) public blankSpace?: number;

  protected noData: boolean = false;

  protected options: any;
  private eventHandlers = [
    {
      event: "draw",

      fn(x: { type: string; value: number; element: any; meta: MetaInfo }) {
        if (x?.meta?.color) {
          x.element._node.setAttribute("style", "stroke: " + x.meta.color);
        }
        x.element._node.addEventListener("click", (e: Event) => {
          console.log("clicked chart " + x?.meta?.aic);
          if (x?.meta?.aic) {
            // richtiges vue statt this raussuchen
            const vue = getChartFromElement(
              e.target,
              (v) => v.showDialog !== undefined
            ) as CChart;
            if (vue) {
              vue.showDialog(x.meta.aic);
            } else {
              console.warn("link to chart component not found!");
            }
          }
        });
      },
    },
  ];

  protected responsiveOptions?: any;

  protected chartData: ChartData[] = [];

  protected showLoading: boolean = true;
  protected unit: string = "";

  @Watch("size")
  private resize() {
    this.refresh();
  }

  public dataLoaded(data: QueryResponse) {
    try {
      if ((data?.data?.length ?? 0) === 0) {
        this.noData = true;
        return;
      }
      this.params.queryData = this.lastQueryResponse = data;
      const seriesColumns = getKZColumnsByPrefix(
        this.seriesColumnKZ,
        data.columns
      ) as DataColumn[];
      const aicColumn = getKZColumn("aic", data.columns);
      const header = data.header;
      if (header.abfJahr) {
        this.periodisch = true;
      }
      // console.warn("no aic Column found in chart!", this.params.query);

      const lblsColumn =
        getKZColumn(this.labelsColumnKZ, data.columns) ??
        getKZColumn("label", data.columns);

      if ((seriesColumns?.length ?? 0) > 0) {
        const e = seriesColumns[0].meta.einheit;
        this.unit = " " + getEinheitFromColumn(seriesColumns[0]).kennung;
      }

      const chartData: ChartData[] = [];
      if (this.QuickViewMode) {
        this.QvAICs.forEach((aic) => chartData.push({ aic, labels: [], series: [] }));
      }
      this.setMinMax(data);
      data.data.forEach((row, rix) => {
        const aic = aicColumn ? getValue(row, aicColumn) : -1;

        let chart = chartData.find((c) => c.aic === aic);
        if (!chart) {
          // wenn wir ein quickview haben, dann nur die quickview aics anzeigen
          if (this.QuickViewMode) {
            return;
          }
          chart = { aic, labels: [], series: [] };
          chartData.push(chart);
        }
        if (seriesColumns?.length === 1 && lblsColumn) {
          chart.labels = data.data.map((r) => {
            let lbl: any = getDisplay(r, lblsColumn);
            if (typeof lbl === "object") {
              lbl = lbl.bezeichnung;
            }
            return lbl ?? "-";
          });
        } else {
          chart.labels = seriesColumns?.map((s) => s.title) ?? [];
        }
        if (this.periodisch) {
          // Jahreschart
          chart.name = getKZValueDisplay(row, aicColumn.meta.kz, data.columns);
          const series: any = seriesColumns?.map((s) => {
            const val = row[s.name];
            return val;
          });
          chart.series.push(series);
        } else if (this.chartType === "BalkenStackedMinMax") {
          const s = seriesColumns[0];
          const val = this.checkVal4metaData(row, s, chart.aic);
          if (chart.series.length === 0) {
            (chart.series as ChartSeriesData[][]).push([val[0]]);
            (chart.series as ChartSeriesData[][]).push([val[1]]);
          } else {
            (chart.series[0] as ChartSeriesData[]).push(val[0]);
            (chart.series[1] as ChartSeriesData[]).push(val[1]);
          }
        } else {
          for (let ix = 0; ix < seriesColumns.length; ix++) {
            const s = seriesColumns[ix];
            // }

            // seriesColumns.forEach((s, ix) => {
            if (chart) {
              const cix =
                chartData.findIndex((c) => c.aic === aic) ?? this.chartData.length;
              const val = this.checkVal4metaData(row, s, chart.aic);
              if (this.chartType === "Pie") {
                val[0].meta.tooltip =
                  chart.labels[rix] +
                  ": " +
                  parseFloat("" + val[0].value).toFixed(2) +
                  this.unit;
              }
              // TODO Tooltip im Pie mit Label befüllen
              if (ix >= chart.series.length) {
                const x: ChartSeriesData[] = val;
                const array = chart.series as ChartSeriesData[][];
                array.push(x);
              } else {
                try {
                  const array = chart.series[ix] as ChartSeriesData[];
                  array.push(val[0]);
                } catch {
                  // nix tun
                }
              }
            } // );
          }
        }
      });

      this.setOptions();
      this.options.width = "100%";
      if (this.chartType === "Bar" || this.chartType === "BalkenStackedMinMax") {
        if (this.chartType === "BalkenStackedMinMax") {
          this.options.stackBars = true;
          // this.options.stackMode = "overlap";
        }
        if (this.chartOrientation === "horizontal") {
          if (this.QuickViewMode) {
            // wenn in "quickviewMode" dann
            this.options.height = this.size; // größe übernehmen
            this.options.axisY.offset = 50; // bezeichnungen schmal machen...
          } else if (chartData[0].series && chartData[0].series.length > 0) {
            const a = chartData[0].labels;
            this.options.height = a?.length * 40;
          }
        }
      } else if (this.chartType === "Pie") {
        this.options.showLabel = false; // keine Labels
        this.options.chartPadding = 30;
        this.options.axisY.offset = this.options.axisX.offset = 20; // verhindert einen NaN (not a number fehler im PIE)
        if (this.size) {
          this.options.width = this.options.height = this.size;
        }
      } else {
        this.options.height = "100%"; // data.data.length * 17;//this.height ?? 300;
      }

      this.chartData = chartData;

      this.options.seriesBarDistance = 36;
      if (this.chartType === "Pie") {
        this.chartData.forEach((chart) => {
          const arraySeries = chart.series as ChartSeriesData[][];
          chart.series = arraySeries[0];
        });
      }
      if (this.chartType === "Bar" || this.chartType === "BalkenStackedMinMax") {
        // if (this.chartType === "Bar") {
        //   this.options.seriesBarDistance = 60;
        // }
        this.options.horizontalBars = this.chartOrientation === "horizontal";
      }
    } finally {
      this.showLoading = false;
    }
  }

  private setMinMax(data: QueryResponse) {
    const minCol = getKZColumn("min", data.columns);
    const maxCol = getKZColumn("max", data.columns);

    this.minColor = minCol?.meta?.farbe ?? this.minColor;
    this.maxColor = maxCol?.meta?.farbe ?? this.maxColor;

    this.minColumn = minCol;
    this.maxColumn = maxCol;
  }

  private transformTooltipTextFunction(meta: string, val: string, xx: any) {
    try {
      const metaInfo: MetaInfo = JSON.parse(meta.replace(/\&quot;/g, '"'))?.data;
      if (metaInfo?.tooltip) {
        return metaInfo.tooltip;
      }
      const lbl: string = parseFloat(val).toFixed(2) + this.unit;
      return lbl;
    } catch (err) {
      return val + "!";
    }
  }

  private checkVal4metaData(
    row: any,
    s: DataColumn,
    aic: number,
    tooltip?: string
  ): ChartSeriesData[] {
    const vals: ChartSeriesData[] = [];

    const value = getValue(row, s);
    const valKZ = getDisplay(row, s);
    let defaultColor = "green";
    if (s.meta.farbe) {
      defaultColor = s.meta.farbe;
    }
    let min = this.min;
    if (this.minColumn) {
      min = getValue(row, this.minColumn) ?? this.min;
    }
    let max = this.max;
    if (this.maxColumn) {
      max = getValue(row, this.maxColumn) ?? this.max;
      max = roundNumber(max, 2);
    }
    // TODO: Tooltip in Pie aus label befüllen
    if (!tooltip && this.chartType === "BalkenStackedMinMax") {
      tooltip = valKZ + " (" + min + "/" + max + ")";
    }
    console.log(value);
    // maximum auf bar schauen

    if (value > max && this.chartType === "BalkenStackedMinMax") {
      vals.push({
        value: max,
        meta: { aic, value, color: defaultColor, tooltip },
      });
      vals.push({
        value: value - max,
        meta: { aic, value, color: this.maxColor, tooltip },
      });
    } else if (value < min && this.chartType === "BalkenStackedMinMax") {
      if (min >= 0) {
        // wenn min >=0 dann nur einen gelben balken
        vals.push({
          value: 0,
          meta: { aic, value, color: this.minColor, tooltip },
        });
        vals.push({
          value: value + min,
          meta: { aic, value, color: this.minColor, tooltip },
        });
      } else {
        vals.push({
          // wenn min < 0 dann min grün lassen...
          value: min,
          meta: { aic, value, tooltip },
        });
        vals.push({
          value: value + min,
          meta: { aic, value, color: this.minColor, tooltip },
        });
      }
      // vals.push({ value: value - min, meta: { aic, value, ix, cix, color: this.minColor } });
    } else {
      if (this.chartType === "BalkenStackedMinMax") {
        vals.push({
          value,
          meta: { aic, value, color: defaultColor, tooltip },
        });
        vals.push({
          value: 0,
          meta: { aic, value, color: this.minColor, tooltip },
        });
      } else if (value > max && this.chartType === "Bar") {
        vals.push({
          value,
          meta: { aic, value, color: this.maxColor, tooltip },
        });
      } else if (this.chartType === "Bar") {
        vals.push({
          value,
          meta: { aic, value, tooltip },
        });
      } else {
        vals.push({
          value,
          meta: { aic, value, color: defaultColor, tooltip },
        });
      }
    }
    return vals;
  }

  private logData() {
    console.log(this.chartType);
    console.log(this.chartData);
  }
  private printData() {
    const chartist = this.$refs.chartist as Vue[];

    const svg = chartist[0].$el?.children[0];
    if (svg.tagName.toLowerCase() === "svg") {
      printVueElement(
        svg as HTMLElement,
        this.title ?? this.params?.queryData?.header?.bezeichnung
      );
      return;
    } else {
      showSnackbar({
        text: "Druck dieses Charts derzeit nicht implementiert!",
        duration: 3000,
        color: "red",
      });
    }
  }

  private getChartStyle(cix: number, chart?: ChartData) {
    if (this.QuickViewMode || this.size) {
      // if (this.orientation === "vertical") {
      return {
        height: this.size + "px",
        background: getAlternateColor(cix),
        overflow: "hidden",
        padding: 0,
      };
      // } else {
      //   return {
      //     width: this.size + "px",
      //     background: getAlternateColor(cix),
      //     overflow: "hidden",
      //   };
      // }
    }
    return { background: getAlternateColor(cix) };
  }

  protected QvAICsChanged() {
    this.refresh();
  }
}
