import { render, staticRenderFns } from "./BaseVGrid.vue?vue&type=template&id=11b969ec&scoped=true"
import script from "./BaseVGrid.vue?vue&type=script&lang=ts"
export * from "./BaseVGrid.vue?vue&type=script&lang=ts"
import style0 from "./BaseVGrid.vue?vue&type=style&index=0&id=11b969ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11b969ec",
  null
  
)

export default component.exports