import { render, staticRenderFns } from "./KVStufen.vue?vue&type=template&id=86889b20&scoped=true"
import script from "./KVStufen.vue?vue&type=script&lang=ts"
export * from "./KVStufen.vue?vue&type=script&lang=ts"
import style0 from "./KVStufen.vue?vue&type=style&index=0&id=86889b20&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86889b20",
  null
  
)

export default component.exports